/**
 * Created by php-developer on 10.10.17.
 */
/*global jQuery, exports, window, i18n, ModuleContextMenuActions*/
(function ($, global) {
    "use strict";

    if (global.ModuleContextMenu === undefined) {
        var actions,
            ModuleContextMenu = function (canvas, options) {
                this.config = $.extend({
                    canvasSelector: '.upper-canvas',
                    dataTitleClass: 'data-title'
                }, options);
                this.canvas = canvas;
                this.items = [];
            };

        ModuleContextMenu.prototype.initItems = function () {
            this.items.push({
                name: i18n.t('common.Module_options'),
                callback: function(itemKey, opt, rootMenu, originalEvent){
                    actions.startOptionsDialog();
                }
            });
            this.items.push({
                name: i18n.t('common.Equipment'),
                callback: function(itemKey, opt, rootMenu, originalEvent){
                    actions.startEquipmentDialog();
                }
            });
            this.items.push({
                name: i18n.t('common.Connection_types'),
                callback: function(itemKey, opt, rootMenu, originalEvent){
                    actions.startConnectionTypesDialog();
                }
            });
            this.items.push({
                name: i18n.t('common.Sidewalls'),
                callback: function(itemKey, opt, rootMenu, originalEvent){
                    actions.startSidewallsDialog();
                }
            });
            this.items.push({
                name: i18n.t('common.Painting'),
                callback: function(itemKey, opt, rootMenu, originalEvent){
                    actions.startPaintingDialog();
                }
            });
            this.items.push({
                name: i18n.t('common.Show_info'),
                callback: function(itemKey, opt, rootMenu, originalEvent){
                    actions.startInfoDialog();
                }
            });
            this.items.push({
                name: i18n.t('common.Download_specification'),
                callback: function(itemKey, opt, rootMenu, originalEvent){
                    actions.downloadSpecification();
                }
            });
            this.items.push("---------");
            this.items.push({
                name: i18n.t('common.Remove_module'),
                callback: function(itemKey, opt, rootMenu, originalEvent){
                    actions.deleteModule();
                }
            });
        };

        ModuleContextMenu.prototype.initialize = function () {
            this.initItems();
            var $upperCanvas = $(this.config.canvasSelector),
                that = this;
            actions = new ModuleContextMenuActions();
            actions.setCanvas(this.canvas);

            $.contextMenu({
                selector: this.config.canvasSelector,
                className: this.config.dataTitleClass,
                trigger: 'right',
                items: this.items,
                delay: 1000,
                hideOnSecondTrigger: true,
                itemClickEvent: "click",
                events: {
                    show : function(options){
                        var ctxTarget = that.canvas.findTarget(fabric.window.event);
                        if (ctxTarget !== undefined) {
                            actions.setContextTarget(ctxTarget);
                            $('.' + that.config.dataTitleClass).attr('data-menutitle', ctxTarget.module.config.name);
                            return true;
                        } else {
                            return false;
                        }
                    }
                }
            });
            $upperCanvas.on('click', function (e) {
                $upperCanvas.contextMenu("hide");
                actions.unsetContextTarget();
            });
        };

        global.ModuleContextMenu = ModuleContextMenu;
    }

}(jQuery, typeof exports !== 'undefined' ? exports : this));