/**
 * Created by php-developer on 18.07.17.
 */
/*global jQuery, window, navbar, interactiveGridRow, angleFieldWidget, baseModelForm, afterAjaxUpdateReinitializer, modalReposition, DynamicAssetsLoader, ToggleSpinnerService, SpecificationDownloadSpinnerService*/
(function ($) {
    "use strict";

    if (window.global === undefined) {
        window.global = {
            initialized: false,
            initCounters: function  (selector) {
                $(selector).counter({
                    plusButtonClass: 'btn btn-default counter-plus',
                    minusButtonClass: 'btn btn-default counter-minus',
                    plus: '<span class="fa fa-chevron-right"></span>',
                    minus: '<span class="fa fa-chevron-left"></span>',
                    pattern: '[0-9]+',
                    min: 0
                });
            },
            initialize: function () {
                window.dynamicAssetsLoader = new DynamicAssetsLoader();
                navbar.initialize();

                interactiveGridRow.initialize();
                angleFieldWidget.initialize();
                baseModelForm.initialize();
                afterAjaxUpdateReinitializer.initialize();
                modalReposition.initialize();
                this.initSpinnerService();
                this.initialized = true;
            },
            initSpinnerService: function () {
                return new ToggleSpinnerService();
            }
        };
    }
    if(!window.global.initialized) {
        window.global.initialize();
    }
}(jQuery));