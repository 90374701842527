/**
 * Created by hybris-frontend on 26.04.17.
 */
/*global console, jQuery, encodeURI, confirm, clickModalButton, window, addAjaxScripts, utils, alert*/
(function ($) {
    "use strict";
    if (typeof window.BulkRemoveButton !== "function") {
        window.BulkRemoveButton = function (options, dataCallback) {
            var config = {
                selector: '#bulk_remove_btn',
                message: 'Вы уверены что хотите удалить элементы?',
                url: '',//'".Yii::app()->createUrl(' / catalog / elements / eraseSelected')."'
                gridId: '',
                checkBoxColumnId: '',
                noSelectedMessage: 'Нет отмеченных записей!',
                noAddressMessage: 'Не задан адрес для обработки запроса !'
            };

            /**
             * Вызов коллбека для обработки данных
             */
            function riseDataCallback(items) {
                var data = {};
                if (typeof dataCallback === "function") {
                    data = dataCallback(items);
                }
                return data;
            }

            function bulkRemove() {
                var items = $.fn.yiiGridView.getChecked(config.gridId, config.checkBoxColumnId);
                if (items.length === 0) {
                    $.notify(config.noSelectedMessage, {globalPosition: 'top right', className: "error"});
                    return false;
                }
                if (config.url.length === 0) {
                    $.notify(config.noAddressMessage, {globalPosition: 'top right', className: "error"});
                    return false;
                }
                if (!confirm(config.message)) {
                    return false;
                }
                jQuery.ajax({
                    'url': config.url,
                    'dataType': 'json',
                    'type': 'POST',
                    'data': riseDataCallback(items),
                    'success': function (jdata) {
                        jQuery.notify(jdata.data, {globalPosition: 'top right', className: jdata.type});
                        if (jdata.type === 'success') {
                            jQuery.fn.yiiGridView.update(config.gridId);
                        }
                        return false;
                    }
                });
            }

            this.initialize = function () {
                $.extend(config, options);
                $(config.selector).off("click.BulkRemoveButton").on("click.BulkRemoveButton", function (e) {
                    e.preventDefault();
                    bulkRemove();
                });
            };
        };
    }
}(jQuery));