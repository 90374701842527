/**
 * Created by pavel on 25.01.18.
 */
/*global jQuery, window, document, utils, i18n*/
(function ($) {
    "use strict";

    if (window.SpecificationDownloadSpinnerService === undefined) {
        var SpecificationDownloadSpinnerService = function () {
            this.config = {
                token_name: 'specificationDownloadToken',
                attempts: 30,
                form_selector: '#specification_download'
            };
            this.downloadTimer = null;
            this.downloadToken = null;
            this.attempts = 30;
            this.initialize();
        };

        SpecificationDownloadSpinnerService.prototype.setFormToken = function () {
            this.downloadToken = new Date().getTime().toString();
            $("input[name=" + this.config.token_name +"]").val(this.downloadToken);
        };

        SpecificationDownloadSpinnerService.prototype.initialize = function() {
            var that = this;
            $(this.config.form_selector).on('submit', function () {
                that.setFormToken();
                that.observe();
                return true;
            });
        };

        SpecificationDownloadSpinnerService.prototype.observe = function () {
            var that = this;
            $(document).trigger('add-spinner');
            if (this.downloadTimer !== null) {
                this.stopObserving();
            }
            this.downloadTimer = window.setInterval(function() {
                var token = $.cookie(that.config.token_name);
                if((token === that.downloadToken) || (that.attempts == 0) ) {
                    that.stopObserving();
                }
                this.attempts--;
            }, 1000 );
        };

        SpecificationDownloadSpinnerService.prototype.stopObserving = function () {
            $(document).trigger('remove-spinner');
            if (this.downloadTimer) {
                window.clearInterval(this.downloadTimer);
            }
            this.downloadTimer = null;
            $.removeCookie(this.config.token_name);
            this.attempts = this.config.attempts;
        };

        window.SpecificationDownloadSpinnerService = SpecificationDownloadSpinnerService;
    }

}(jQuery));
