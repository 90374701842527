/**
 * Created by УховаяУха on 25.01.2018.
 */
/*global jQuery, window, document, utils, i18n*/
(function ($) {
    "use strict";

    if (window.ToggleSpinnerService === undefined) {
        var ToggleSpinnerService = function () {
            this.config = {
                start_spinner_selector: '[data-run-spinner]',
                start_spinner_event: 'add-spinner',
                stop_spinner_event: 'remove-spinner'
            };
            this.initialize();
        };

        ToggleSpinnerService.prototype.initialize = function() {
            $(this.config.start_spinner_selector).on('click', function (e) {
                utils.addBigSpinner($('body'), i18n.t('common.please_wait_spinner'));
            });
            $(document).on(this.config.start_spinner_event, function () {
                utils.addBigSpinner($('body'), i18n.t('common.please_wait_spinner'));
            });
            $(document).on(this.config.stop_spinner_event, function () {
                utils.removeBigSpinner();
            });
        };

        window.ToggleSpinnerService = ToggleSpinnerService;
    }

}(jQuery));