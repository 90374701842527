/**
 * Created by php-developer on 01.09.17.
 */
/*global jQuery, window, navbar*/
(function ($) {
    "use strict";

    var config = {
        modalSelector: '.modal:visible'
    };

    /**
     * Reposition of current modal window
     */
    function doModalReposition() {
        var $modal = $(config.modalSelector),
            offset = $modal.offset(),
            windowHeight = $(window).height(),
            modalHeight = parseInt($modal.outerHeight()),
            repositionValue = windowHeight / 2 - modalHeight / 2;

        // var modalTop = $modal.data('default-offset-top');
        // if (modalTop === undefined) {
        //     $modal.attr('data-default-offset-top', offset.top);
        //     modalTop = offset.top;
        // }
        // if (modalTop > ($(window).scrollTop() + modalHeight)) {
        //     $modal.offset({top: modalTop - modalHeight, left: offset.left});
        // } else {
        if (repositionValue <= 0) {
            repositionValue = 50;
        }
        $modal.offset({top: $(window).scrollTop() + repositionValue, left: offset.left});
        // }
    }

    function initialize() {
        $(document).on('reposition.bs.modal', doModalReposition);
        $(document).on('shown.bs.modal', doModalReposition);
    }

    if (window.modalReposition === undefined) {
        window.modalReposition = {
            initialize: initialize
        };
    }

}(jQuery));