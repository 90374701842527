/**
 * Created by php-developer on 23.08.17.
 */
/*global jQuery, window, navbar, interactiveGridRow, ArticlesSuggestionBlock*/
(function ($) {
    "use strict";

    var config = {
        blockSelector: '.articles-suggestion-block',
        paintingInputSelector: "input[name$='\\\[painting\\\]']"
    };

    function initialize() {
        $(config.blockSelector).each(function () {
            var $this = $(this),
                articlesSuggestionBlock = new ArticlesSuggestionBlock({
                    blockSelector: this
                });
            articlesSuggestionBlock.initialize();
            $this.data('articlesSuggestionBlock', articlesSuggestionBlock);
        });

        $(document).on("setInputReadonly", function (event, data) {
            var $block = $(event.target),
                $paintingInput = $block.find(config.paintingInputSelector);
            if (data.unsetVal) {
                $paintingInput.prop("checked", false);
            }
            $paintingInput.attr("disabled", "disabled");
        });

        $(document).on("setInputWritable", function (event) {
            var $block = $(event.target),
                $paintingInput = $block.find(config.paintingInputSelector);
            $paintingInput.removeAttr("disabled");
        });
    }

    if (window.modelRecommendedAccessories === undefined) {
        window.modelRecommendedAccessories = {
            initialize: initialize
        };
    }

}(jQuery));