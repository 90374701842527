/**
 * Created by php-developer on 01.08.17.
 */
/*global jQuery, window, mathUtils*/
(function ($) {
    "use strict";

    if (window.ConstructorModulesDisplaySettings === undefined) {

        var ConstructorModulesDisplaySettings = function () {
            this.config = {
                figureInputSelector: this.renderSelector('figure'),
                observableInputSelector: this.renderSelector('snap_length')
            };
            this.type = "rect";
            this.calculator = function () {
                var value = parseFloat($(this.config.observableInputSelector).val());
                $(this.renderSelector('width')).val(value * 2);
                $(this.renderSelector('height')).val(value);
            };
        };

        ConstructorModulesDisplaySettings.prototype.initialize = function () {
            this.type = $(this.config.figureInputSelector).val();
            this.initCalculator();
            this.initObservable();
        };

        ConstructorModulesDisplaySettings.prototype.initObservable = function () {
            var that = this;
            $(this.config.observableInputSelector).on('keypress keyup', function (e) {
                var val = $(this).val();
                if (!isNaN(val)) {
                    return that.calculator();
                }
            });
        };

        ConstructorModulesDisplaySettings.prototype.renderSelector = function (selector) {
            return "input[name='Constructor\[" + selector + "\]']";
        };

        ConstructorModulesDisplaySettings.prototype.setObservableSelectors = function (selectors) {
            var observableSelector = "",
                that = this;
            $.each(selectors, function (i, selector) {
                if (observableSelector.length > 0) {
                    observableSelector += ",";
                }
                observableSelector += that.renderSelector(selector);
            });
            if (observableSelector.length > 0) {
                this.config.observableInputSelector = observableSelector;
            }
        };

        ConstructorModulesDisplaySettings.prototype.initCalculator = function () {
            switch (this.type) {
                case 'MOC45':
                    this.setObservableSelectors(["snap_length", "append"]);
                    this.calculator = function () {
                        var snapSide = parseFloat($(this.renderSelector('snap_length')).val()),
                            append = parseFloat($(this.renderSelector('append')).val()),
                            topCornerTo2 = mathUtils.toRadians(45 / 2);
                        $(this.renderSelector('width')).val(2 * snapSide * Math.sin(topCornerTo2));
                        $(this.renderSelector('height')).val(append + (snapSide * Math.cos(topCornerTo2)));
                    };
                    break;
                case 'MIC45':
                    this.setObservableSelectors(["snap_length", "append", "endAngle", "startAngle", "radius"]);
                    this.calculator = function () {
                        var snapSide = parseFloat($(this.renderSelector('snap_length')).val()),
                            append = parseFloat($(this.renderSelector('append')).val()),
                            radius = parseFloat($(this.renderSelector('radius')).val()),
                            topCornerTo2 = mathUtils.toRadians(45 / 2),
                            angleOfArc = parseFloat($(this.renderSelector('endAngle')).val()) * Math.PI - parseFloat($(this.renderSelector('startAngle')).val()) * Math.PI,
                            smallWidth = 2 * radius * Math.sin(angleOfArc / 2) + 1;
                        $(this.renderSelector('width')).val(smallWidth + (2 * snapSide * Math.sin(topCornerTo2)));
                        $(this.renderSelector('height')).val(append + (snapSide * Math.cos(topCornerTo2)));
                        $(this.renderSelector('smallWidth')).val(smallWidth);
                    };
                    break;
                case 'MIC90':
                    this.setObservableSelectors(["snap_length", "append", "endAngle", "startAngle", "radius"]);
                    this.calculator = function () {
                        var snapSide = parseFloat($(this.renderSelector('snap_length')).val()),
                            append = parseFloat($(this.renderSelector('append')).val()),
                            radius = parseFloat($(this.renderSelector('radius')).val()),
                            topCornerTo2 = mathUtils.toRadians(90 / 2),
                            angleOfArc = parseFloat($(this.renderSelector('endAngle')).val()) * Math.PI - parseFloat($(this.renderSelector('startAngle')).val()) * Math.PI,
                            smallWidth = radius * Math.sin(angleOfArc / 2) + 1;

                        $(this.renderSelector('width')).val(2 * (smallWidth + (snapSide * Math.sin(topCornerTo2))));
                        $(this.renderSelector('height')).val(append + (snapSide * Math.cos(topCornerTo2)));
                        $(this.renderSelector('smallWidth')).val(smallWidth);
                    };
                    break;
                case 'MOC90':
                    this.setObservableSelectors(["snap_length", "append", "smallWidth"]);
                    this.calculator = function () {
                        var snapSide = parseFloat($(this.renderSelector('snap_length')).val()),
                            append = parseFloat($(this.renderSelector('append')).val()),
                            smallWidth = parseFloat($(this.renderSelector('smallWidth')).val()),
                            topCornerTo2 = mathUtils.toRadians(90 / 2);

                        $(this.renderSelector('width')).val(2 * (smallWidth + (snapSide * Math.sin(topCornerTo2))));
                        $(this.renderSelector('height')).val(append + (snapSide * Math.cos(topCornerTo2)));
                    };
                    break;
                default:

            }
        };

        window.ConstructorModulesDisplaySettings = ConstructorModulesDisplaySettings;
    }

}(jQuery));