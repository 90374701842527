/**
 * Created by php-developer on 23.08.17.
 * @event setInputReadonly
 * @event setInputWritable
 */
/*global console, jQuery, encodeURI, confirm, clickModalButton, window, alert, BulkRemoveButton, addAjaxScripts*/
(function ($) {
    "use strict";
    if (typeof window.ArticlesSuggestionBlock !== "function") {

        var ArticlesSuggestionBlock = function (options) {

            var _defaultConfig = {
                blockSelector: '.articles-suggestion-block',
                blockReadonlyToggleInputSelector: '.elements-group-toggle',
                blockArticlesSuggest: '.category-row-elements',
                radioButtonSelector: 'input[type=radio]',
                selectAllToggleSelector: "button[data-toggle='select2-select-all']"
            };

            this.config = $.extend({}, _defaultConfig, options);
            this.$block = $(this.config.blockSelector);
            this.$blockReadOnlyToggle = this.$block.find(this.config.blockReadonlyToggleInputSelector);
            this.$suggest = this.$block.find(this.config.blockArticlesSuggest);
            this.$selectAllToggle = this.$block.find(this.config.selectAllToggleSelector);
        };

        /**
         * Позволяет выбрать все айтемы в select2
         */
        ArticlesSuggestionBlock.prototype.selectAllRowItems = function ($trigger) {
            var select2Id = $trigger.data("target");
            if (!$trigger.hasClass("disabled")) {
                $(select2Id + " > option").prop("selected", "selected");
                $(select2Id).trigger("change");
            }
        };

        /**
         * Убирает класс и аьтрибут disabled у кнопки выбора всех айтемов
         */
        ArticlesSuggestionBlock.prototype.enableSelect2SelectAllButton = function () {
            this.$selectAllToggle.removeAttr('disabled').removeClass('disabled');
        };

        /**
         * Добавляет класс и аьтрибут disabled к кнопке выбора всех айтемов
         */
        ArticlesSuggestionBlock.prototype.disableSelect2SelectAllButton = function () {
            this.$selectAllToggle.attr('disabled', 'disabled').addClass('disabled');
        };

        /**
         * @returns {boolean}
         */
        ArticlesSuggestionBlock.prototype.isInputReadonly = function () {
            return this.$suggest.attr("readonly") !== undefined && this.$suggest.attr("readonly").length > 0;
        };

        /**
         * Устанавливает инпут в readonly
         * @param unsetVal
         */
        ArticlesSuggestionBlock.prototype.setInputReadonly = function (unsetVal) {
            this.$suggest.attr('readonly', 'readonly');
            if (unsetVal) {
                this.$suggest.val(null).trigger('change');
            }
            this.$suggest.prop('readonly', true);
            this.$block.trigger('setInputReadonly', {unsetVal: unsetVal});
        };

        /**
         * Убирает аттрибут readonly
         */
        ArticlesSuggestionBlock.prototype.setInputWritable = function () {
            this.$suggest.removeAttr('readonly');
            this.$block.trigger('setInputWritable');
        };

        /**
         * Если у элемента есть select2 и он readonly, снимаем атрибут
         * Если атрибута нет ,ставим атрибут
         */
        ArticlesSuggestionBlock.prototype.readWriteToggle = function () {
            if (this.isInputReadonly()) {
                this.setInputWritable();
                this.enableSelect2SelectAllButton();
            } else {
                this.setInputReadonly(true);
                this.disableSelect2SelectAllButton();
            }
        };

        /**
         * при каждом клике:
         * 1. Меняется состояние Readonly для дочерних по отношению к текущему элементу
         *     селектов с размерами элементов
         * 2. Устанавливаются активные категории
         * 3. Вешаем события на базовые элементы
         */
        ArticlesSuggestionBlock.prototype.groupToggleChange = function () {
            var that = this;

            this.$block.find(this.config.radioButtonSelector).each(function () {
                var $iterationToggle = $(this);
                if ($iterationToggle.get(0) !== that.$blockReadOnlyToggle.get(0)) {
                    that.setInputReadonly(true);
                    that.disableSelect2SelectAllButton();
                    if ($iterationToggle.prop('type').toLowerCase() === "radio") {
                        $iterationToggle.removeAttr('checked');
                    }
                }
            });
            this.readWriteToggle();
        };

        ArticlesSuggestionBlock.prototype.initialize = function () {
            var that = this;

            this.$blockReadOnlyToggle.on("change.ArticlesSuggestionBlock", function () {
                that.groupToggleChange();
            });

            this.$selectAllToggle.on('click', function () {
                that.selectAllRowItems($(this));
            });
        };


        window.ArticlesSuggestionBlock = ArticlesSuggestionBlock;
    }
}(jQuery));