/**
 * Created by php-developer on 09.10.17.
 */
/*global jQuery, define, require, Handlebars, window, scriptPath*/
(function ($, global) {
    "use strict";

    var MathUtils = function () {

        /**
         * Измеряет длину отрезка
         * @param A
         * @param B
         * @returns {number}
         */
        function lineLength(A, B) {
            return Math.sqrt(Math.pow(A.x - B.x, 2) + Math.pow(A.y - B.y, 2));
        }

        /**
         * Измеряет угол наклона прямой в радианах
         * @param A
         * @param B
         */
        function lineAngle(A, B) {
            return Math.atan((B.y - A.y) / (B.x - A.x));
        }

        /**
         * Считает корректирову для треугольников на стыках
         * http://www.litunovskiy.com/gamedev/intersection_of_two_circles/
         * @param A
         * @param C
         * @param BA
         * @param BC
         * @param AC
         * @param reverse
         * @returns {{}}
         */
        function determineTriangleCorrectionPoint(A, C, BA, BC, AC, reverse) {
            var B = {}, P0, b, a, h, nDelta = (AC - Math.abs(BC - BA)) / 2;
            if (Math.abs(AC - Math.abs(BC - BA)) < 5) {
                var nSign = -1;//sgn(BC - BA);
                B.x = C.x + (BC - nDelta) * (A.x - C.x) / AC * nSign;
                B.y = C.y + (BC - nDelta) * (A.y - C.y) / AC * nSign;
            }
            else if (Math.abs(AC - (BC + BA)) < 5) {
                B.x = C.x + (BC + nDelta) * (A.x - C.x) / AC;
                B.x = C.y + (BC + nDelta) * (A.y - C.y) / AC;
            } else if (AC < BC + BA && AC > Math.abs(BC - BA)) {
                b = (Math.pow(BA, 2) - Math.pow(BC, 2) + Math.pow(AC, 2)) / (2 * AC);
                a = AC - b;
                h = Math.sqrt(Math.pow(BC, 2) - Math.pow(a, 2));
                P0 = {x: C.x + a / AC * (A.x - C.x), y: C.y + a / AC * (A.y - C.y)};
                if (reverse) {
                    B.x = P0.x + h / AC * (A.y - C.y);
                    B.y = P0.y - h / AC * (A.x - C.x);
                } else {
                    B.x = P0.x - h / AC * (A.y - C.y);
                    B.y = P0.y + h / AC * (A.x - C.x);
                }
            }
            return B;
        }

        /**
         * Смещает указанную точку вертикально вверх на указанную длину с учётом поворота координат
         * @param point
         * @param length
         * @param cosTh
         * @param sinTh
         * @returns {{}}
         */
        function movePointUp(point, length, cosTh, sinTh) {
            return {x: point.x + length * sinTh, y: point.y - length * cosTh};
        }

        /**
         * Смещает указанную точку вертикально вниз на указанную длину с учётом поворота координат
         * @param point
         * @param length
         * @param cosTh
         * @param sinTh
         * @returns {{}}
         */
        function movePointDown(point, length, cosTh, sinTh) {
            return {x: point.x - length * sinTh, y: point.y + length * cosTh};
        }

        /**
         * Смещает указанную точку горизонтально вправо на указанную длину с учётом поворота координат
         * @param point
         * @param length
         * @param cosTh
         * @param sinTh
         * @returns {{}}
         */
        function movePointRight(point, length, cosTh, sinTh) {
            return {x: point.x + length * cosTh, y: point.y + length * sinTh};
        }

        /**
         * Смещает указанную точку горизонтально влево на указанную длину с учётом поворота координат
         * @param point
         * @param length
         * @param cosTh
         * @param sinTh
         * @returns {{}}
         */
        function movePointLeft(point, length, cosTh, sinTh) {
            return {x: point.x - length * cosTh, y: point.y - length * sinTh};
        }

        /**
         * Смещает указанную точку по диагонали прямоугольника
         * c заданными длиной и шириной с правого нижнего в левый верхний угол
         * с учётом поворота координат
         * @param point
         * @param height
         * @param width
         * @param cosTh
         * @param sinTh
         * @returns {{}}
         */
        function movePointDiagonallyRightToLeft(point, height, width, cosTh, sinTh) {
            return {x: point.x - width * cosTh + height * sinTh, y: point.y - width * sinTh - height * cosTh};
        }

        /**
         * Повернуть точку дуги на угол
         * Точка дуги и угол задаются в радианах
         * @param center
         * @param radius
         * @param arcPoint
         * @param angle
         * @returns {{x: *, y: *}}
         */
        function rotateArcPointToAngle(center, radius, arcPoint, angle) {
            var cosTh = Math.cos(arcPoint + angle),
                sinTh = Math.sin(arcPoint + angle);
            return movePointRight(center, radius, cosTh, sinTh);
        }

        /**
         * Конвертирует градусы в радианы
         */
        function toRadians(degrees) {
            return degrees * Math.PI / 180;
        }

        /**
         * Конвертирует радианы в градусы
         * @param radians
         * @returns {number}
         */
        function toDegrees(radians) {
            return radians * 180 / Math.PI;
        }

        this.initialize = function () {
            this.lineLength = lineLength;
            this.lineAngle = lineAngle;
            this.determineTriangleCorrectionPoint = determineTriangleCorrectionPoint;
            this.movePointUp = movePointUp;
            this.movePointDown = movePointDown;
            this.movePointRight = movePointRight;
            this.movePointLeft = movePointLeft;
            this.movePointDiagonallyRightToLeft = movePointDiagonallyRightToLeft;
            this.rotateArcPointToAngle = rotateArcPointToAngle;
            this.toRadians = toRadians;
            this.toDegrees = toDegrees;
        };

        return this.initialize();
    };
    if (global.mathUtils === undefined) {
        global.mathUtils = new MathUtils();
    }
}(jQuery, typeof exports !== "undefined" ? exports : this));