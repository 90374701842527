/**
 * Created by php-developer on 09.10.17.
 */
/*global jQuery, exports, VerticesCalculator, mathUtils*/
(function (global) {

    "use strict";

    var fabric = global.fabric || (global.fabric = {}),
        MOCVerticesCalculator = function (group, figure) {
            this.group = group;
            this.figure = figure;
        };

    /**
     *
     * @param snapZone
     * @returns {*}
     */
    MOCVerticesCalculator.prototype.calculateTopLeftPoint = function (snapZone) {
        var A = snapZone.vertices[1],
            C = snapZone.vertices[0],
            BA, BC, AC, snapFigureMediumTLPoint, snapFigureTLPoint,
            theta = fabric.util.degreesToRadians(this.group.angle),
            sinTh = Math.sin(theta),
            cosTh = Math.cos(theta),
            height = this.group.height + 2 * this.group.strokeWidth,
            width = this.group.width + 2 * this.group.strokeWidth;

        if (snapZone.snapMap.static[1] === "br") {
            BA = mathUtils.lineLength(this.group.oCoords.bl, snapZone.movingVertices.bl); //катет
            BC = mathUtils.lineLength(this.group.oCoords.bl, snapZone.movingVertices.tl); //катет
            AC = mathUtils.lineLength(A, C) - this.group.strokeWidth; //гипотенуза
            snapFigureMediumTLPoint = mathUtils.determineTriangleCorrectionPoint(A, C, BA, BC, AC, false);
            snapFigureTLPoint = mathUtils.movePointUp(snapFigureMediumTLPoint, height, cosTh, sinTh);

        //Стыковка угловых модулей слева к фигуре
        } else {
            BA = mathUtils.lineLength(this.group.oCoords.br, snapZone.movingVertices.br); //катет
            BC = mathUtils.lineLength(this.group.oCoords.br, snapZone.movingVertices.tr); //катет
            AC = mathUtils.lineLength(A, C); //гипотенуза
            snapFigureMediumTLPoint = mathUtils.determineTriangleCorrectionPoint(A, C, BA, BC, AC, true);
            snapFigureTLPoint = mathUtils.movePointDiagonallyRightToLeft(snapFigureMediumTLPoint, height, width, cosTh, sinTh);
        }

        return snapFigureTLPoint;
    };

    global.MOCVerticesCalculator = MOCVerticesCalculator;

}(typeof exports !== "undefined" ? exports : this));