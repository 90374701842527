/**
 * Created by php-developer on 01.08.17.
 */
/*global jQuery, window, navbar, interactiveGridRow*/
(function ($) {
    "use strict";

    var config = {
            isAngularInput: '[data-toggle="angle-field"]',
            angleInput: '[data-role="angle-field"]'
        },

        IS_NOT_ANGULAR = 0;

    function toggleAngleInput(disabled) {
        var $angleInput = $(config.angleInput);
        if (disabled) {
            $angleInput.val(0);
            $angleInput.trigger("change");
            $angleInput.attr('disabled', 'disabled');
        } else {
            $angleInput.removeAttr('disabled');
        }
    }

    function initialize() {
        $(config.isAngularInput).on('change', function () {
            var $input = $(this);
            toggleAngleInput(parseInt($input.val()) === IS_NOT_ANGULAR);
        });
    }

    if (window.angleFieldWidget === undefined) {
        window.angleFieldWidget = {
            initialize: initialize
        };
    }

}(jQuery));