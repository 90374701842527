/**
 * Created by php-developer on 08.11.17.
 */
/*global jQuery, exports, MOC45Sidewall, MIC45Sidewall, MOC90Sidewall, MIC90Sidewall, RectSidewall, mathUtils, utils*/
(function ($, global) {

    "use strict";

    var fabric = global.fabric || (global.fabric = {}),
        Sidewall = function (group, figure) {
            this.group = group;
            this.figure = figure;
            this.config = null;
        };

    Sidewall.DEFAULTS = {
        font_size: 12,
        font: 'helvetica',
        defaultPositionValues: {
            top: 0,
            left: 0,
            angle: 0,
            height: 0
        }
    };

    Sidewall.prototype.getDefaults = function () {
        return Sidewall.DEFAULTS;
    };

    /**
     * Устанавливвает конфиг
     * @param options
     */
    Sidewall.prototype.setConfig = function (options) {
        this.config = $.extend({}, this.getDefaults(), options);
    };

    /**
     *
     * @returns {{top: (*|Number), left: (*|Number), angle: number}}
     */
    Sidewall.prototype.getLeftSidewallPosition = function () {
        return Sidewall.DEFAULTS.defaultPositionValues;
    };

    /**
     *
     * @returns {{top: (*|Number), left: (*|Number), angle: number}}
     */
    Sidewall.prototype.getRightSidewallPosition = function () {
        return Sidewall.DEFAULTS.defaultPositionValues;
    };

    /**
     *
     */
    Sidewall.prototype.addSidewalls = function () {
        var leftSidewall = this.getLeftSidewallPosition(),
            rightSidewall = this.getRightSidewallPosition(),
            textSize = {width: 0, height: 0};
        if (this.group.module.config.left_sidewall !== null && this.group.module.config.left_sidewall !== undefined) {
            this.group.add(new fabric.Rect({
                left: leftSidewall.left,
                top: leftSidewall.top,
                angle: leftSidewall.angle,
                width: 5,
                height: leftSidewall.height,
                fill: 'rgba(255,255,255,1)',
                stroke: 'rgba(0,0,0,1)',
                name: 'left_sidewall'
            }));
            textSize = this.calculateTextSize(this.group.module.config.left_sidewall);
            this.group.add(new fabric.Textbox(this.group.module.config.left_sidewall, {
                left: leftSidewall.left,
                top: leftSidewall.top - textSize.height,
                angle: leftSidewall.angle,
                fontSize: this.config.font_size,
                fontFamily: this.config.font,
                name: 'left_sidewall'
            }));
        }
        if (this.group.module.config.right_sidewall !== null && this.group.module.config.right_sidewall !== undefined) {
            this.group.add(new fabric.Rect({
                left: rightSidewall.left,
                top: rightSidewall.top,
                angle: rightSidewall.angle,
                width: 5,
                height: rightSidewall.height,
                fill: 'rgba(255,255,255,1)',
                stroke: 'rgba(0,0,0,1)',
                name: 'right_sidewall'
            }));
            textSize = this.calculateTextSize(this.group.module.config.right_sidewall);
            this.group.add(new fabric.Textbox(this.group.module.config.right_sidewall, {
                left: rightSidewall.left - textSize.width,
                top: rightSidewall.top - textSize.height,
                angle: rightSidewall.angle,
                fontSize: this.config.font_size,
                fontFamily: this.config.font,
                name: 'right_sidewall'
            }));
        }
        if (this.group.module.config.connection !== null && this.group.module.config.connection !== undefined) {
            textSize = this.calculateTextSize(this.group.module.config.connection);
            this.group.add(new fabric.Textbox(this.group.module.config.connection, {
                left: rightSidewall.left - textSize.width,
                top: rightSidewall.top - textSize.height,
                angle: rightSidewall.angle,
                fontSize: this.config.font_size,
                fontFamily: this.config.font,
                name: 'right_sidewall'
            }));
        }
        if (this.group.module.config.left_sidewall !== null || this.group.module.config.right_sidewall !== null || this.group.module.config.connection !== null) {
             //this.group.addWithUpdate();
            this.group.set('dirty', true);
            this.group.module.canvas.renderAll();
        }
    };

    /**
     * @param text string
     * @returns {*|{width: number, height: number}}
     */
    Sidewall.prototype.calculateTextSize = function (text) {
        return utils.textSize(text, {'fontSize': this.config.font_size, 'fontFamily': this.config.font});
    };

    /**
     * Удаление старых боковин
     */
    Sidewall.prototype.clearSidewalls = function () {
        var that = this,
            toRemove = [];
        that.group.forEachObject(function (object) {
            if (object !== undefined && (object.name === 'left_sidewall' || object.name === 'right_sidewall')) {
                toRemove.push(object);
            }
        });
        $.each(toRemove, function (i, object) {
            that.group.remove(object);
            that.group.set('dirty', true);
            that.group.module.canvas.renderAll();
        });
    };

    global.Sidewall = Sidewall;

}(jQuery, typeof exports !== "undefined" ? exports : this));