/**
 * Created by hybris-frontend on 05.05.17.
 */
/*global console, jQuery, encodeURI, confirm, clickModalButton, window, alert, BulkRemoveButton, addAjaxScripts*/
(function ($) {
    "use strict";
    if (typeof window.ArticlesSuggestionForm !== "function") {
        window.ArticlesSuggestionForm = function (options) {
            var config = {
                    parentCategoryRowSelector: '.parent-category-row',
                    baseElementsGroupToggleSelector: 'input.elements-group-toggle',
                    baseElementsBlockSelector: '#base_elements',
                    baseElementInputIdMask: '#base_elements_input_',
                    baseCategoryRowSelector: '.base-category-row',
                    baseElementsRowSelector: '.base-category-row-elements',
                    loadElementsButtonSelector: '#load_type_elements',
                    formSubmitButtonSelector: '#form_submit_btn',
                    resetBaseElementsButtonSelector: '#reset_base_elements',
                    elementsContainer: '#elements',
                    childElementsGroupToggleSelector: '.child-elements-group-toggle',
                    childElementInputIdMask: '#elements_input_',
                    islandTypeCheckboxSelector: '#Types_island_type',
                    rackTypeAlertSelector: '.type_rack',
                    standTypeSelector: '#Types_stand_type',
                    select2SelectAllButtonSelector: 'button[data-toggle="select2-select-all"]'
                },

                /**
                 * Блок с базовыми элементами
                 */
                _$baseElementsBlock,

                /**
                 * Категории, доступные для отметки
                 */
                _$baseParentCategoryRows,

                /**
                 * Количество доступных для отметки категорий
                 */
                _baseParentCategoryRowsCount,

                /**
                 * @type {Array}
                 */
                _activeBaseCategories = []; // Активные категории

            /**
             * Установка активных категорий
             * Для категорий верхнего уровня необходимо проверять, чтобы хотя бы один дочерний элемент был заполнен
             */
            function setActiveBaseCategories() {
                var activeCategories = [];
                _$baseParentCategoryRows.each(function () {
                    var $this = jQuery(this),
                        $activeSubcategories = $this.find(config.baseElementsGroupToggleSelector + ':checked');

                    if ($activeSubcategories.length > 0) {
                        activeCategories.push($($activeSubcategories[0]).data('category-id'));
                    }
                });
                _activeBaseCategories = activeCategories;
            }

            /**
             * Разблокировать кнопку закрузки эл-в
             */
            function enableLoadButton() {
                $(config.loadElementsButtonSelector).removeClass('disabled');
            }

            /**
             * Заблокировать кнопку закрузки эл-в
             */
            function disableLoadButton() {
                var $button = $(config.loadElementsButtonSelector);
                if (!$button.hasClass('disabled')) {
                    $(config.loadElementsButtonSelector).addClass('disabled');
                }
            }

            /**
             * Для активных субкатегорий необходимо проверять чтобы хотя бы один элемент был указан
             */
            function checkActiveBaseSubcategories() {
                var $elementInputs = $(config.baseElementsRowSelector),
                    filledRows = 0,
                    filledBaseSubcategories = [];
                $elementInputs.each(function () {
                    var $this = $(this),
                        parentId = $this.data('parent-id');
                    if ($this.val() !== null && $this.val().length > 0 && filledBaseSubcategories.indexOf(parentId) === -1) {
                        filledBaseSubcategories.push(parentId);
                        filledRows += 1;
                    }
                });
                if (filledRows === _baseParentCategoryRowsCount) {
                    enableLoadButton();
                } else {
                    disableLoadButton();
                }
            }

            /**
             * Позволяет выбрать все айтемы в select2
             */
            function selectAllRowItems($trigger) {
                var select2Id = $trigger.data("target");
                if (!$trigger.hasClass("disabled")) {
                    $(select2Id + " > option").prop("selected", "selected");
                    $(select2Id).trigger("change");
                }
            }

            /**
             * Убирает класс и аьтрибут disabled у кнопки выбора всех айтемов
             */
            function enableSelect2SelectAllButton(select2Id) {
                $("button[data-target='" + select2Id + "']").removeAttr('disabled').removeClass('disabled');
            }

            /**
             * Добавляет класс и аьтрибут disabled к кнопке выбора всех айтемов
             */
            function disableSelect2SelectAllButton(select2Id) {
                $("button[data-target='" + select2Id + "']").attr('disabled', 'disabled').addClass('disabled');
            }

            /**
             * @param selector
             * @returns {boolean}
             */
            function isInputReadonly(selector) {
                var $input = $(selector);
                return $input.attr("readonly") !== undefined && $input.attr("readonly").length > 0;
            }

            /**
             * Устанавливает инпут в readonly
             * @param selector
             */
            function setInputReadonly(selector, unsetVal) {
                var $select2Input = $(selector);
                $select2Input.attr('readonly', 'readonly');
                if (unsetVal) {
                    $select2Input.val(null).trigger('change');
                }
                $select2Input.prop('readonly', true);
            }

            /**
             * Убирает аттрибут readonly
             * @param selector
             */
            function setInputWritable(selector) {
                var $select2Input = $(selector);
                $select2Input.removeAttr('readonly');
            }

            /**
             * Перехыватываем активацию элементов,
             * Вешаем событие изменение элеента
             * Если один элемент выбран, сравниваем число Категорий, доступные для отметки с числом выбранных элементов
             * Если эти два числа совпадают, активируем кнопку
             */
            function addBaseElementEvents() {
                var $baseElementInputs = $(config.baseElementsRowSelector);
                $baseElementInputs.each(function () {
                    var $this = $(this);
                    $this.off('change.addElementEvents');
                    if (!isInputReadonly(this)) {
                        $this.on('change.addElementEvents', checkActiveBaseSubcategories);
                    }
                });
            }

            /**
             * Если у элемента есть select2 и он readonly, снимаем атрибут
             * Если атрибута нет ,ставим атрибут
             */
            function readWriteToggle(selector) {
                if (isInputReadonly(selector)) {
                    setInputWritable(selector);
                    enableSelect2SelectAllButton(selector);
                } else {
                    setInputReadonly(selector, true);
                    disableSelect2SelectAllButton(selector);
                }
            }

            /**
             * при каждом клике:
             * 1. Меняется состояние Readonly для дочерних по отношению к текущему элементу
             *     селектов с размерами элементов
             * 2. Устанавливаются активные категории
             * 3. Вешаем события на базовые элементы
             * @param $toggle {jQuery}
             */
            function groupToggleChange($toggle, elementInputMask) {
                $toggle.parents(config.parentCategoryRowSelector + "," + config.baseCategoryRowSelector).find('input[type=radio]').each(function () {
                    var $iterationToggle = $(this),
                        selector = elementInputMask + $iterationToggle.data('category-id');
                    if ($iterationToggle.get(0) !== $toggle.get(0)) {
                        setInputReadonly(selector, true);
                        disableSelect2SelectAllButton(selector);
                        if ($iterationToggle.prop('type').toLowerCase() === "radio") {
                            $iterationToggle.removeAttr('checked');
                        }
                    }
                });
                readWriteToggle(elementInputMask + $toggle.data('category-id'));
            }

            /**
             * Собирает значения базовых элементов
             */
            function collectActiveBaseElementsValues() {
                var elements = {};
                $.each(_activeBaseCategories, function () {
                    elements[this] = $(config.baseElementInputIdMask + this).val();
                });
                return elements;
            }

            /**
             * Блокировка базовых элментов
             */
            function blockBaseElements() {
                _$baseElementsBlock.find('select').each(function () {
                    setInputReadonly(this);
                });
                _$baseElementsBlock.find('input').each(function () {
                    $(this).prop({disabled: true});
                });
                disableLoadButton();
            }

            /**
             * Разблокировать базовые элементы
             */
            function unblockBaseElements() {
                _$baseElementsBlock.find('select').each(function () {
                    if (this.value !== null) {
                        setInputWritable(this);
                    }
                });
                _$baseElementsBlock.find('input').each(function () {
                    $(this).prop({disabled: false});
                });
            }

            /**
             * Разблокировать базовые элементы
             */
            function resetBaseElements() {
                if (confirm('Все значения, заявленные для небазовых элементов, будут сброшены')) {
                    unblockBaseElements();
                    $(config.elementsContainer).html('');
                    $(config.formSubmitButtonSelector).hide();
                    $(config.resetBaseElementsButtonSelector).hide();
                    enableLoadButton();
                } else {
                    blockBaseElements();
                }
            }

            /**
             * При клике загружаются элементы в соотвтетствии с размерами базовых элементов
             */
            function loadNonBaseElements($toggle) {
                $.ajax({
                    type: 'get',
                    dataType: 'html',
                    data: {elements: collectActiveBaseElementsValues()},
                    url: $toggle.attr("href"),
                    success: function (data) {
                        // Вывести полученные элементы под базовыми
                        var content = $(data).filter('#ajax-content');
                        $(config.elementsContainer).html(content.html());
                        addAjaxScripts(data);
                        // Заблокировать все базовые элементы и кнопку
                        blockBaseElements();
                        jQuery(config.formSubmitButtonSelector).show();
                        jQuery(config.resetBaseElementsButtonSelector).show();
                    }
                });
            }

            /**
             * Инициализация формы с базовыми элементами
             */
            function baseFormInitialize() {
                //Для update они называются base-category, а для insert parent-category
                _$baseParentCategoryRows = $(config.baseCategoryRowSelector);
                if (_$baseParentCategoryRows.length === 0) {
                    _$baseParentCategoryRows = $(config.parentCategoryRowSelector);
                }
                _$baseElementsBlock = $(config.baseElementsBlockSelector);
                _baseParentCategoryRowsCount = _$baseParentCategoryRows.length;

                $(config.baseElementsGroupToggleSelector).on('change', function () {
                    groupToggleChange($(this), config.baseElementInputIdMask);
                    setActiveBaseCategories();
                    addBaseElementEvents();
                });

                $(config.loadElementsButtonSelector).on("click", function (e) {
                    e.preventDefault();
                    loadNonBaseElements($(this));
                });

                $(config.resetBaseElementsButtonSelector).on("click", function (e) {
                    e.preventDefault();
                    resetBaseElements();
                });

                addBaseElementEvents();

                /**
                 * При клике тоглится подсказка об островном типе
                 */
                $(config.islandTypeCheckboxSelector).on("click", function () {
                    $(config.rackTypeAlertSelector).toggle(this.checked);
                });

                $(document).on("change.RackTypeWizard", config.childElementsGroupToggleSelector, function () {
                    groupToggleChange($(this), config.childElementInputIdMask);
                });

                $(document).on('click', config.select2SelectAllButtonSelector, function () {
                    selectAllRowItems($(this));
                });
            }

            /**
             * Загружает базовые элементы
             */
            function loadBaseElements($toggle) {
                var $checked = $toggle.find('input:checked'),
                    url = $checked.parent('label').data('url');
                $(config.formSubmitButtonSelector).hide();
                $.ajax({
                    type: 'get',
                    dataType: 'html',
                    url: url,
                    success: function (data) {
                        var content = $(data).filter('#ajax-content');
                        $(config.baseElementsBlockSelector).html(content.html());
                        addAjaxScripts(data);
                        baseFormInitialize();
                    }
                });
            }

            this.initialize = function () {
                $.extend(config, options);
                var $standType = $(config.standTypeSelector);
                if ($standType.length === 0) {
                    baseFormInitialize();
                } else {
                    $standType.off('change').on('change', function () {
                        loadBaseElements($(this));
                        $standType.find('label').addClass('disabled');
                    });
                }
            };
        };
    }
}(jQuery));