/**
 * Created by php-developer on 08.08.17.
 */
/*global jQuery, window, navbar, interactiveGridRow*/
(function ($) {
    "use strict";

    var config = {
            isBarInputSelector: "#CaseBaseModelForm_bar"
        },
        $barInput;

    function getTargetArray() {
        return $barInput.data('target');
    }

    function toggleTarget(disable, targets) {
        $.each(targets, function () {
            var $target = $(this),
                type = $target.attr('type');
            if (disable) {
                if (type === "text") {
                    $target.val("");
                } else if (type === "checkbox") {
                    $target.attr("checked", false);
                }
                $target.attr("disabled", "disabled");
            } else {
                $target.removeAttr("disabled");
            }
        });
    }

    function initialize() {
        $barInput = $(config.isBarInputSelector);
        $barInput.on('change', function () {
            var targets = getTargetArray();
            if ($barInput.is(":checked")) {
                toggleTarget(true, targets);
            } else {
                toggleTarget(false, targets);
            }
        });
    }

    if (window.baseModelForm === undefined) {
        window.baseModelForm = {
            initialize: initialize
        };
    }

}(jQuery));


