/**
 * Created by php-developer on 09.10.17.
 */
/*global jQuery, exports, VerticesCalculator, mathUtils*/
(function (global) {

    "use strict";

    var fabric = global.fabric || (global.fabric = {}),
        RectVerticesCalculator = function (group, figure) {
            this.group = group;
            this.figure = figure;
        };

    RectVerticesCalculator.prototype.calculate = function (params) {
        var vertices = {};

        vertices.tl = new fabric.Point(params.coords.x - params.offsetX, params.coords.y - params.offsetY);
        vertices.tr = new fabric.Point(vertices.tl.x + (params.currentWidth * params.cosTh), vertices.tl.y + (params.currentWidth * params.sinTh));
        vertices.bl = new fabric.Point(vertices.tl.x - (params.currentHeight * params.sinTh), vertices.tl.y + (params.currentHeight * params.cosTh));
        vertices.br = new fabric.Point(params.coords.x + params.offsetX, params.coords.y + params.offsetY);
        vertices.ml = new fabric.Point((vertices.tl.x + vertices.bl.x) / 2, (vertices.tl.y + vertices.bl.y) / 2);
        vertices.mt = new fabric.Point((vertices.tr.x + vertices.tl.x) / 2, (vertices.tr.y + vertices.tl.y) / 2);
        vertices.mr = new fabric.Point((vertices.br.x + vertices.tr.x) / 2, (vertices.br.y + vertices.tr.y) / 2);
        vertices.mb = new fabric.Point((vertices.br.x + vertices.bl.x) / 2, (vertices.br.y + vertices.bl.y) / 2);
        vertices.mtr = new fabric.Point(vertices.mt.x + params.sinTh * this.figure.rotatingPointOffset, vertices.mt.y - params.cosTh * this.figure.rotatingPointOffset);

        return vertices;
    };

    /**
     *
     * @param snapZone
     * @returns {*}
     */
    RectVerticesCalculator.prototype.calculateTopLeftPoint = function (snapZone) {
        var snapFigureTLPoint,
            theta = fabric.util.degreesToRadians(this.group.angle),
            sinTh = Math.sin(theta),
            cosTh = Math.cos(theta),
            height = this.group.height,
            width = this.group.width;

        snapFigureTLPoint = mathUtils.movePointLeft(snapZone.vertices[0], 1, cosTh, sinTh);

        if (snapZone.snapMap.static[0] === "tl" && snapZone.snapMap.static[1] === "bl" && snapZone.snapMap.moving[0] === "tr") {
            snapFigureTLPoint = mathUtils.movePointLeft(snapFigureTLPoint, width - 2, cosTh, sinTh);

        } else if (snapZone.snapMap.static[0] === "mb" && snapZone.snapMap.static[1] === "br") {
            snapFigureTLPoint = mathUtils.movePointLeft(snapZone.staticVertices.br, 1, cosTh, sinTh);

        } else if (snapZone.snapMap.static[0] === "mb" && snapZone.snapMap.static[1] === "bl") {
            snapFigureTLPoint = mathUtils.movePointLeft(snapZone.staticVertices.bl, width - 1, cosTh, sinTh);

        } else if (snapZone.snapMap.static[0] === "tr" && snapZone.snapMap.static[1] === "br" && snapZone.snapMap.moving[0] === "bl") {
            snapFigureTLPoint = mathUtils.movePointUp(snapZone.staticVertices.tr, height - 1, cosTh, sinTh);

        } else if (snapZone.snapMap.static[0] === "tl" && snapZone.snapMap.static[1] === "bl" && snapZone.snapMap.moving[0] === "br") {
            snapFigureTLPoint = mathUtils.movePointDiagonallyRightToLeft(snapZone.staticVertices.tl, height - 1, width, cosTh, sinTh);

        } else if (snapZone.snapMap.static[0] === "br" && snapZone.snapMap.static[1] === "bl") {
            snapFigureTLPoint = mathUtils.movePointUp(snapZone.staticVertices.br, height - 1, cosTh, sinTh);
        }
        return snapFigureTLPoint;
    };

    global.RectVerticesCalculator = RectVerticesCalculator;

}(typeof exports !== "undefined" ? exports : this));