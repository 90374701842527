/**
 * Created by php-developer on 05.10.17.
 */
/*global jQuery, window, navbar, LineModule, LineCornerModule, modules, LineModelSearchForm, ModuleContextMenu, FabricSnap, ArticlesSearchForm, DynamicLoadingForm, SaveForEqualsWidget, modalFormAjaxSubmit, PaintingFormDialog, PaintingFormWidget, ConstructorData*/
(function ($, global) {
    "use strict";

    var config = {
        equipmentDialogFormSelector: "#EquipmentDialogForm",
        sidewallDialogFormSelector: "#SidewallsDialogForm",
        canvasId: 'canvas'
    };

    function initializeDownloadSpinnerService() {
        return new SpecificationDownloadSpinnerService();
    }

    function initialize() {
        var fabric = global.fabric || (global.fabric = {}),
            fabricCanvas = global.fabricCanvas || (global.fabricCanvas = new fabric.Canvas(config.canvasId));

        fabric.Object.prototype.transparentCorners = false;

        var fabricSnap,
            lineModelSearchForm = new LineModelSearchForm(),
            articlesSearchForm = new ArticlesSearchForm(),
            paintingFormWidget = new PaintingFormWidget(),
            moduleContextMenu = new ModuleContextMenu(fabricCanvas),
            saveForEquals = new SaveForEqualsWidget(),
            constructorData = new ConstructorData(),
            $canvas = $("#" + config.canvasId);

        initializeDownloadSpinnerService();

        constructorData.initialize({
            loadEvents: 'addLineModule::success FabricSnap::movedLineModule ModuleContextMenuActions::hiddenSidewallDialog',
            dataUrl: $canvas.data('modules-load-url'),
            moduleSettings: $canvas.data('settings') || undefined
        });
        constructorData.loadData();

        // инициализируем поисковую форму для добавления новых модулей
        lineModelSearchForm.initialize({initializeOn: 'shown.bs.modal'});

        // Форма поиска артикулов
        articlesSearchForm.initialize({
            postArrayName: 'ModuleEquipmentForm',
            removeRows: false
        });

        paintingFormWidget.initialize();

        // Инициализируем контекстное меню
        moduleContextMenu.initialize();

        // Сохранение для аналогичных модулей
        saveForEquals.initialize();

        // AJAX сохранение формы в модальном окне
        modalFormAjaxSubmit.initialize();

        // инициализируем стыковку
        $(document).on("ConstructorData::dataLoaded", function () {
            if (fabricSnap === undefined) {
                fabricSnap = new FabricSnap(fabricCanvas, constructorData.typeOfEquipment);
                fabricSnap.initialize();
            }
        });

        //инициализируем форму комплектующих
        $(document).on('shown.bs.modal', function (e) {
            var $modal = $(e.target);
            if ($modal.find(config.equipmentDialogFormSelector).length > 0) {
                var dynamicLoadingForm = new DynamicLoadingForm({
                    dynamicFormSelector: config.equipmentDialogFormSelector,
                    postArrayName: "ModuleEquipmentForm"
                });
                dynamicLoadingForm.initialize();
            }
        });

        // Отключаем элементы управления выделением (динамически созданной группы)
        fabricCanvas.on('selection:created', function (e) {
            if (e.target.module === undefined) {
                e.target.set({
                    lockScalingX: true,
                    lockScalingY: true,
                    hasControls: false
                });
            }
        });
    }

    if (global.constructorPage === undefined) {
        global.constructorPage = {
            initialize: initialize
        };
    }

}(jQuery, typeof exports !== 'undefined' ? exports : this));