/*global define, require, Handlebars, window, scriptPath*/
(function ($) {
    "use strict";

    var templates = {},

        url = scriptPath + "/../templates/";

    if (window.hbs === undefined) {
        window.hbs = {
            /**
             * Рендеринг шаблона
             * @param template
             * @param callback
             */
            render: function (template, callback) {
                if (templates[template] === undefined) {
                    $.ajax({
                        url: url + template,
                        success: function (raw) {
                            templates[template] = Handlebars.compile(raw);
                            callback(templates[template]);
                        }
                    });
                } else {
                    callback(templates[template]);
                }
            }
        };
    }
}(jQuery));