/**
 * Created by php-developer on 13.10.17.
 */
/*global jQuery, exports, utils, confirm, dynamicAssetsLoader*/
(function ($, global) {
    "use strict";

    if (global.ModalDialog === undefined) {
        var ModalDialog = function (options) {
            this.config = null;
            this.$trigger = null;
            this.initialize(options);
        };

        ModalDialog.prototype.initialize = function (options) {
            this.config = $.extend({
                trigger: '[data-role="ajax-modal-trigger"]',
                modalSelector: '.modal',
                url: null,
                message: null,
                modalId: null
            }, options);
        };

        /**
         * Инициализирует клик по триггеру для запуска модального окна
         */
        ModalDialog.prototype.initializeTrigger = function () {
            var that = this;
            this.$trigger = $(this.config.trigger);
            this.config.url = this.$trigger.attr("href");
            this.$trigger.unbind('click.ModalDialog').on('click.ModalDialog', function (e) {
                e.preventDefault();
                if (that.config.message && !confirm(that.config.message)) {
                    return false;
                }
                that.toggleSpinner();
                return that.getDialogContent();
            });
        };

        /**
         * Спиннер для триггера
         * @param detach
         */
        ModalDialog.prototype.toggleSpinner = function (detach) {
            if (this.$trigger) {
                utils.toggleSmallSpinner(this.$trigger, detach ? null : this.$trigger.offset());
            }
        };

        /**
         * Получение HTML контента диалога и его инициализация
         */
        ModalDialog.prototype.getDialogContent = function () {
            var that = this;
            $.ajax({
                url: this.config.url,
                dataType: 'html',
                type: 'POST',
                success: function (response) {
                    var responseJson = utils.isJson(response);
                    // Сообщения
                    if (responseJson) {
                        that.notify(responseJson.data, responseJson.type);
                        that.toggleSpinner();
                        return;
                    }
                    that.initializeDialog(response);
                }
            });
        };

        /**
         * Инициализировать диалог из загруженного HTML
         * @param html
         */
        ModalDialog.prototype.initializeDialog = function (html) {
            var that = this,
                modal;

            modal = $(html).filter(this.config.modalSelector);
            $(modal).modal().on('hidden.bs.modal', function (e) {
                if (e.target === this) {
                    $(that.config.modalId).detach();
                    dynamicAssetsLoader.removeAjaxScripts();
                    $(document).trigger('removed.bs.modal', {id: that.config.modalId});
                }
            });
            dynamicAssetsLoader.addAjaxScripts(html);
            that.toggleSpinner(true);
        };

        /**
         * уведомления в стиле VK
         * @param message
         * @param type
         */
        ModalDialog.prototype.notify = function (message, type) {
            $.notify(message, {globalPosition: 'top right', className: type});
        };

        global.ModalDialog = ModalDialog;
    }

}(jQuery, typeof exports !== "undefined" ? exports : this));
