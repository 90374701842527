/**
 * Created by php-developer on 18.07.17.
 */
/*global jQuery, window*/
(function ($) {
    "use strict";

    var config = {
        selector: '.multi-level.dropdown-menu'
    };

    function initialize() {
        $(document).on('click', config.selector, function (e) {
            e.stopPropagation();
        });
    }

    if (window.navbar === undefined) {
        window.navbar = {
            initialize: initialize
        };
    }

}(jQuery));