/**
 * Created by php-developer on 28.07.17.
 */
/*global jQuery, window*/
(function ($) {
    "use strict";

    var config = {
        selector: '.interactive-row>td'
    };

    function initialize() {
        $(document).on('click', config.selector, function () {
            var $td = $(this),
                $tr = $td.parent('tr');
            if ($td.attr('class') === undefined || $td.attr('class').indexOf('button') === -1) {
                document.location.href = $tr.find($tr.data('click-button')).attr('href');
            }
        });
    }

    if (window.interactiveGridRow === undefined) {
        window.interactiveGridRow = {
            initialize: initialize
        };
    }

}(jQuery));