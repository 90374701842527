/**
 * Created by php-developer on 05.09.17.
 */
/*global jQuery, window, navbar, interactiveGridRow*/
(function ($) {
    "use strict";

    if (window.DynamicAssetsLoader === undefined) {
        var DynamicAssetsLoader = function () {

            this.config = {
                remoteScriptSelector: 'script[src]'
            };
        };

        DynamicAssetsLoader.globalAddedInlineScripts = [];
        DynamicAssetsLoader.globalAddedRemoteScripts = {};

        /**
         * Determine if Css is added
         */
        DynamicAssetsLoader.prototype.isCssAdded = function (href) {
            var $links = $('link'),
                _is = false;
            $links.each(function () {
                if (this.href === href) {
                    _is = true;
                    return true;
                }
            });
            return _is;
        };

        /**
         * Determine is script src is loaded
         * @param src
         * @returns {boolean}
         */
        DynamicAssetsLoader.prototype.isRemoteJsAdded = function (src) {
            var $scripts = $(this.config.remoteScriptSelector),
                added = false;
            $scripts.each(function () {
                if (this.src === src) {
                    added = true;
                    return true;
                }
            });
            return added;
        };

        /**
         * если тип скрипта - remote
         */
        DynamicAssetsLoader.prototype.isRemoteScript = function (scriptNode) {
            return typeof scriptNode.src === "string" && scriptNode.src.length > 0;
        };

        /**
         *
         * @param scriptNode
         * @returns {boolean}
         */
        DynamicAssetsLoader.prototype.isInGlobalRemoteScripts = function (scriptNode) {
            var id = scriptNode.src.hashCode();
            return DynamicAssetsLoader.globalAddedRemoteScripts[id] !== undefined;
        };

        /**
         * @todo НЕ РАБОТАЕТ, т.к. hashCode возвращает разные значения для одинаковых с виду элементов
         * @param scriptNode
         * @returns {boolean}
         */
        DynamicAssetsLoader.prototype.isInGlobalInlineScripts = function (scriptNode) {
            var id = scriptNode.innerHTML.hashCode();
            return DynamicAssetsLoader.globalAddedInlineScripts[id] !== undefined;
        };

        /**
         * //Если скрипт добавлен
         * @param scriptNode
         * @returns {*}
         */
        DynamicAssetsLoader.prototype.isJsAdded = function (scriptNode) {
            if (this.isRemoteScript(scriptNode)) {
                return this.isInGlobalRemoteScripts(scriptNode) || this.isRemoteJsAdded(scriptNode.src);
            }
            return this.isInGlobalInlineScripts(scriptNode);
        };

        /**
         * Добавляет внешний JS
         */
        DynamicAssetsLoader.prototype.addExternalJs = function (scriptNode) {
            var id = scriptNode.src.hashCode();
            DynamicAssetsLoader.globalAddedRemoteScripts[id] = scriptNode;
        };

        /**
         * Добавляет инлайновый скрипт
         */
        DynamicAssetsLoader.prototype.addInlineJs = function (scriptNode) {
            var script = document.createElement("script"),
                node;
            script.type = "text/javascript";
            script.text = scriptNode.innerHTML;
            node = document.body.appendChild(script);
            DynamicAssetsLoader.globalAddedInlineScripts.push(node);
        };

        /**
         * Удаляет скрипты, добавленные ранее методом addAjaxScripts
         */
        DynamicAssetsLoader.prototype.removeAjaxScripts = function () {
            $.each(DynamicAssetsLoader.globalAddedInlineScripts, function () {
                $(this).remove();
            });
            DynamicAssetsLoader.globalAddedInlineScripts = [];
            DynamicAssetsLoader.globalAddedRemoteScripts = [];
        };

        /**
         * $data[i].src
         * @param scriptNode
         */
        DynamicAssetsLoader.prototype.loadExternalJavascript = function (scriptNode) {
            var that = this;
            $.ajax({
                async: false,
                url: scriptNode.src,
                dataType: "script",
                success: function (data, textStatus, jqxhr) {
                    that.addExternalJs(scriptNode);
                }
            });
        };

        /**
         * Ищет все JS
         * В случае валидного HTML в $html ищет методом filter
         * Если $html не валиден (Например, отсутствует закрывающий тег) - поиск производится методом find
         * @param $html
         */
        DynamicAssetsLoader.prototype.parseJavaScripts = function ($html) {
            var $scripts = $html.filter('script');
            if ($scripts.length === 0) {
                $scripts = $html.find('script');
            }
            return $scripts;
        };

        /**
         * Ищет все link (CSS)
         * В случае валидного HTML в $html ищет методом filter
         * Если $html не валиден (Например, отсутствует закрывающий тег) - поиск производится методом find
         * @param $html
         */
        DynamicAssetsLoader.prototype.parseCSS = function ($html) {
            var $links = $html.filter('link');
            if ($links.length === 0) {
                $links = $html.find('link');
            }
            return $links;
        };

        /**
         * Добавляет скрипты в текущий документ
         * updated 21.05.2015 Происходит проверка, был ли уже загружен скрипт
         * Если не был загружен, то загружается. Если был - пропускается.
         * @param {type} data
         * @returns {undefined}
         */
        DynamicAssetsLoader.prototype.addAjaxScripts = function (data) {
            var $data = $(data),
                $scripts = this.parseJavaScripts($data),
                $links = this.parseCSS($data),
                that = this;

            $scripts.each(function () {
                var scriptNode = this;
                if (!that.isJsAdded(scriptNode)) {
                    if (that.isRemoteScript(scriptNode)) {
                        that.loadExternalJavascript(scriptNode);
                    } else {
                        that.addInlineJs(scriptNode);
                    }
                }
            });
            $links.each(function () {
                var scriptNode = this;
                var $link = $('link'),
                    position = $link[$link.length - 2],
                    $htmlNode = $(scriptNode);
                if (!that.isCssAdded(scriptNode.href)) {
                    $htmlNode.insertAfter(position);
                    DynamicAssetsLoader.globalAddedInlineScripts.push($htmlNode);
                }
            });
        };
        window.DynamicAssetsLoader = DynamicAssetsLoader;
    }
}(jQuery));