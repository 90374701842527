/**
 * Created by php-developer on 10.11.17.
 */
/*global jQuery, exports, LineModule, utils*/
(function ($, global) {
    "use strict";

    var LineRectModule;

    if (global.LineRectModule === undefined) {
        LineRectModule = function (options) {
            this.config = null;
            this.group = null;
            this.module = null;
            this.text = null;
            this.fabric = null;
            return this.initialize(options);
        };

        LineRectModule.prototype = $.extend({}, LineModule.prototype);
        LineRectModule.prototype.constructor = LineRectModule;

        /**
         * Считает пропорционально в зависимости от высоты
         * @returns {number}
         */
        LineRectModule.prototype.calculateWidth = function () {
            var width = parseFloat(this.getDimensionValue('rect', 'width', this.config.defaultWidth));
            if (this.config.width !== undefined) {
                width = this.config.length * parseFloat(this.getDimensionValue('rect', 'height', this.config.defaultHeight)) / this.config.width;
            }
            return width;
        };

        /**
         * Создаёт модуль
         */
        LineRectModule.prototype.addModule = function () {
            this.module = new this.fabric.Rect({
                width: parseFloat(this.calculateWidth()),
                height: parseFloat(this.getDimensionValue('rect', 'height', this.config.defaultHeight)),
                fill: 'rgba(255,255,255,1)',
                stroke: 'rgba(0,0,0,1)'
            });
        };

        global.LineRectModule = LineRectModule;
    }

}(jQuery, typeof exports !== 'undefined' ? exports : this));