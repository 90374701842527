+/**
 * Created by php-developer on 08.11.17.
 */
/*global jQuery, exports, MOC45Sidewall, MIC45Sidewall, MOC90Sidewall, MIC90Sidewall, RectSidewall, mathUtils*/
(function ($, global) {

    "use strict";

    var SidewallFabric = function (group, figure) {
            this.group = group;
            this.figure = figure;
            this.config = {};
        },
        classes = {
            "MOC45": MOC45Sidewall,
            "MIC45": MIC45Sidewall,
            "MOC90": MOC90Sidewall,
            "MIC90": MIC90Sidewall,
            'rect': RectSidewall
        };

    SidewallFabric.prototype.setConfig = function (options) {
        $.extend(this.config, options);
    };

    /**
     * Инициализация модуля
     */
    SidewallFabric.prototype.initialize = function () {
        if (this.figure === null) {
            var groupFigures = this.group.getObjects();
            this.figure = groupFigures[0];
        }
        this.calculator = new classes[this.figure.type](this.group, this.figure);
        this.calculator.setConfig(this.config);
        this.calculator.clearSidewalls();
        this.calculator.addSidewalls();
    };

    global.SidewallFabric = SidewallFabric;

}(jQuery, typeof exports !== "undefined" ? exports : this));