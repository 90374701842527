/**
 * Created by php-developer on 16.08.17.
 */
/*global jQuery, window, navbar, interactiveGridRow, ArticlesSearchForm*/
(function ($) {
    "use strict";

    var config = {};

    function initialize() {
        var articlesSearchForm = new ArticlesSearchForm();
        articlesSearchForm.initialize({
            postArrayName: 'NecessaryAccessories',
            templateName: 'necessaryAccessoriesSearchForm.hbs'
        });
    }

    if (window.modelNecessaryAccessories === undefined) {
        window.modelNecessaryAccessories = {
            initialize: initialize
        };
    }

}(jQuery));