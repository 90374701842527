/**
 * Created by php-developer on 31.08.17.
 */
/*global jQuery, window, addAjaxScripts, LineModelSearchForm*/
(function ($) {
    "use strict";

    var config = {
        typeOfEquipmentToggleSelector: 'a[data-toggle="calculation-ajax-load"]',
        ajaxContentSelector: 'div[data-role="ajax-content"]'
    };

    function loadAjaxContent(url, callback) {
        $.ajax({
            url: url,
            dataType: 'html',
            type: 'get',
            success: callback
        });
    }

    /**
     * Updates Ajax loaded html content
     * @param content
     */
    function updateAjaxContent(content) {
        var $content = $(content).clone(),
            html = "";
        $.each($content, function ($i) {
            if (this !== undefined && this.tagName !== undefined) {
                if (this.tagName.toLowerCase() === "script" || this.tagName.toLowerCase() === "link") {
                    $content.splice($i, 1);
                }
            }
        });
        $.each($content, function () {
            html += (this.outerHTML !== undefined ? this.outerHTML : this.data);
        });
        $(config.ajaxContentSelector).html(html);
        $(document).trigger('updated.calculationPage');
        addAjaxScripts(content);
        $(document).trigger('reposition.bs.modal');
    }

    /**
     * Удаляет select2 из #ajax-content
     */
    function removeSelect2() {
        $(config.ajaxContentSelector).find('select.select2-offscreen').each(function (index, item) {
            $(item).select2('destroy');
        });
    }

    function initialize() {
        $(document).on('click.calculationPage', config.typeOfEquipmentToggleSelector, function (e) {
            e.preventDefault();
            removeSelect2();
            loadAjaxContent(this.href, updateAjaxContent);
        });

        var lineModelSearchForm = new LineModelSearchForm();
        lineModelSearchForm.initialize({initializeOn: 'updated.calculationPage'});
        $(document).on('hidden.bs.modal', function () {
            $.fn.yiiGridView.update("Line-grid");
        });
    }

    if (window.calculationPage === undefined) {
        window.calculationPage = {
            initialize: initialize
        };
    }

}(jQuery));