/**
 * Created by php-developer on 09.10.17.
 */
/*global jQuery, exports, MICVerticesCalculator*/
(function ($, global) {

    "use strict";

    var MIC90VerticesCalculator = function (group, figure) {
        this.group = group;
        this.figure = figure;
    };

    MIC90VerticesCalculator.prototype = $.extend({}, MICVerticesCalculator.prototype);
    MIC90VerticesCalculator.prototype.constructor = MIC90VerticesCalculator;
    global.MIC90VerticesCalculator = MIC90VerticesCalculator;

}(jQuery, typeof exports !== "undefined" ? exports : this));