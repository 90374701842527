/**
 * Created by php-developer on 05.09.17.
 */
/*global console, jQuery, encodeURI, confirm, clickModalButton, window, modal, utils, hbs, Handlebars, dynamicAssetsLoader*/
(function ($) {
    "use strict";
    if (typeof window.LineModelSearchForm !== "function") {
        var formSelector = "#Line-model-search-form",
            LineModelSearchForm = function () {

                var config = {
                        formSelector: formSelector,
                        resultsGridSelector: '#Line-models-grid',
                        resultsContainerSelector: '#results_container',
                        modalSelector: ".modal:visible",
                        inputSelector: 'input',
                        counterInputSelector: 'input.counter',
                        addToLineSelector: '.models-search-result-grid .add-element'
                    },

                    line_id;

                /**
                 * Инициализирует каунтеры
                 * @param selector
                 */
                function initCounters(selector) {
                    $(selector).counter({
                        plusButtonClass: 'btn btn-default counter-plus',
                        minusButtonClass: 'btn btn-default counter-minus',
                        plus: '<span class="fa fa-chevron-right"></span>',
                        minus: '<span class="fa fa-chevron-left"></span>',
                        pattern: '[0-9]+',
                        min: 0
                    });
                }

                /**
                 *
                 * @param $button
                 */
                function addToLine($button) {
                    var $tr = $button.parents('tr'),
                        $inputs = $tr.find(config.inputSelector),
                        data = $tr.data('element');
                    if ($inputs.length > 0) {
                        $inputs.each(function () {
                            var $this = $(this),
                                name = $this.attr("name");
                            data[name] = $this.val();
                        });
                    }
                    if (line_id !== undefined) {
                        data.line_id = line_id;
                    }
                    $.ajax({
                        url: $button.attr('href'),
                        dataType: "json",
                        type: "post",
                        data: data,
                        success: function (response) {
                            if (response.errors !== undefined && response.errors.length === 0) {
                                $.notify("Модуль(и) успешно создан(ы)", {
                                    globalPosition: 'top right',
                                    className: "success"
                                });
                                $(document).trigger("addLineModule::success");
                            }
                            if (response.line_id !== undefined) {
                                line_id = response.line_id;
                            }
                        }
                    });
                }

                /**
                 * Уничтожает грид для предотвращения повторных отправок
                 */
                function destructLineModelsGrid() {
                    var id = config.resultsGridSelector.slice(1),
                        settings;

                    if ($.fn.yiiGridView !== undefined) {
                        settings = $.fn.yiiGridView.settings[id];
                    }
                    if (settings !== undefined) {
                        $(settings.updateSelector).off('click.yiiGridView');
                        $(document).off('click.yiiGridView', settings.updateSelector);
                        $(document).off('change.yiiGridView keydown.yiiGridView', settings.filterSelector);
                        $(document).off('click.yiiGridView', '#' + id + ' .' + settings.tableClass + ' > tbody > tr');
                        $(document).off('click.yiiGridView', '#' + id + ' .select-on-check-all');
                        $(document).off('click.yiiGridView', '#' + id + ' .select-on-check');
                        delete $.fn.yiiGridView.settings[id];
                    }
                }

                /**
                 *
                 * @param e
                 * @returns {boolean}
                 */
                function submitSearchForm(e) {
                    e.preventDefault();
                    e.stopImmediatePropagation();//I can not find the other variant to stop sending twice
                    var $this = $(this),
                        $form = $this.closest('form'),
                        $resultContainer = $(config.resultsContainerSelector);

                    $.ajax({
                        url: $this.attr("action"),
                        type: 'get',
                        dataType: 'html',
                        data: $form.serialize(),
                        success: function (data) {
                            var content = utils.parseHtmlOnly(data, undefined, true);
                            $resultContainer.html(content);
                            $(document).trigger('reposition.bs.modal');

                            destructLineModelsGrid();

                            dynamicAssetsLoader.addAjaxScripts(data);
                            // setResultGrid();
                            initCounters(config.resultsGridSelector + " " + config.counterInputSelector);
                        }
                    });
                    return false;
                }

                /**
                 * Initializes submit action
                 */
                function initializeEventHandlers(e) {
                    var $modal = $(config.modalSelector),
                        $form = $modal.find(config.formSelector);
                    if ($form.length > 0) {
                        $form.off('submit.LineModelSearchForm').on('submit.LineModelSearchForm', submitSearchForm);
                    }
                    line_id = undefined;
                }

                this.initialize = function (options) {
                    $.extend(config, options);
                    $(document).on(config.initializeOn, initializeEventHandlers);
                    $(document).off('click.LineModelSearchForm', config.addToLineSelector)
                        .on('click.LineModelSearchForm', config.addToLineSelector, function (e) {
                            e.preventDefault();
                            addToLine($(this));
                        });
                };
            };

        LineModelSearchForm.collectGridFilter = function (id, options) {
            var data = $(formSelector).serialize();
            options.data = options.data === undefined ? data : options.data + "&" + data;
        };

        window.LineModelSearchForm = LineModelSearchForm;
    }
}(jQuery));