/**
 * Created by php-developer on 07.11.17.
 */
/*global jQuery, exports, LineRectModule, LineCornerModule, SidewallFabric*/
(function ($, global) {
    "use strict";

    if (global.ConstructorData === undefined) {
        var CORNER_NONE = 0,
            CORNER_IC = 1,
            CORNER_OC = 2,

            ConstructorData = function () {
                this.config = null;
                this.fabricCanvas = null;
                this.figures = null;
                this.typeOfEquipment = null;
                this._modulePosition = null;
                this.xhr = null;
            };

        ConstructorData.DEFAULTS = {
            dataUrl: "",
            loadEvents: "",
            moduleSettings: {
                font: "helvetica",
                font_size: 10,
                dimensions: []
            }
        };

        ConstructorData.prototype.initialize = function (options) {
            var that = this;
            this.fabricCanvas = global.fabricCanvas || (global.fabricCanvas = new fabric.Canvas('canvas'));
            this.initFigures();
            this.initModulePosition();
            this.config = $.extend({}, ConstructorData.DEFAULTS, options);
            $(document).on(this.config.loadEvents, function (e) {
                that.loadData();
            });
            $(document).on('FabricSnap::moduleSaved', function (e, data) {
                that.updateSidewalls(data);
            });
        };

        /**
         * Инициализируем фигуры (модули)
         */
        ConstructorData.prototype.initModulePosition = function () {
            this._modulePosition = {
                x: 0,
                y: 0
            };
        };

        /**
         * Инициализируем фигуры (модули)
         */
        ConstructorData.prototype.initFigures = function () {
            this.figures = global.figures || (global.figures = []);
        };

        /**
         * Обновляет у всех модулей информацию о боковинах
         */
        ConstructorData.prototype.updateSidewalls = function (data) {
            var modulesData = {};
            if (data.response.data !== undefined && data.response.data.id !== undefined) {
                if (data.response.data.state_neighbours !== undefined) {
                    $.each(data.response.data.state_neighbours, function (i, neighbour) {
                        modulesData[parseInt(neighbour.id)] = neighbour;
                    });
                    delete data.response.data.state_neighbours;
                }
                modulesData[parseInt(data.response.data.id)] = data.response.data;
                $.each(this.figures, function (i, module) {
                    if (module !== undefined && modulesData.hasOwnProperty(parseInt(module.config.id))) {
                        module.setConfig(modulesData[parseInt(module.config.id)]);
                        module.sidewall.initialize();
                    }
                });
            }
        };

        /**
         * Очищаем canvas и все контексты
         */
        ConstructorData.prototype.clearData = function () {
            if (this.fabricCanvas !== null) {
                this.fabricCanvas.clear();
            }
            this.figures = [];
            this.initModulePosition();
            $(document).trigger('ConstructorData::clear');
        };

        /**
         * Автоматический расчёт положения модуля по умолчанию на странице
         * @returns {{x: number, y: number}}
         */
        ConstructorData.prototype.getDefaultPosition = function () {
            return this._modulePosition;
        };

        /**
         * Устанавливает позицию следующего модуля
         * @param moduleHeight
         */
        ConstructorData.prototype.addNextPosition = function (moduleHeight) {
            this._modulePosition.y += moduleHeight;
        };

        /**
         * Добавляет модуль в канвас
         * @param item
         * @returns {*}
         */
        ConstructorData.prototype.addModule = function (item) {
            var module,
                position = this.getDefaultPosition(),
                options = {
                    x: position.x,
                    y: position.y,
                    id: item.id,
                    name: item.name,
                    short_name: item.short_name,
                    face: parseInt(item.face) === true,
                    bar: parseInt(item.bar) === true,
                    angle: item.state_angle ? parseInt(item.state_angle) : 0,
                    left_sidewall: item.left_sidewall,
                    right_sidewall: item.right_sidewall,
                    connection: item.connection,
                    width: item.width,
                    length: item.length,
                    clearance: item.clearance,
                    max_temperature: item.max_temperature,
                    min_temperature: item.min_temperature,
                    remove_url: item.remove_url,
                    save_state_url: item.save_state_url,
                    options_dialog_url: item.options_dialog_url,
                    equipment_dialog_url: item.equipment_dialog_url,
                    painting_dialog_url: item.painting_dialog_url,
                    connection_types_dialog_url: item.connection_types_dialog_url,
                    sidewalls_dialog_url: item.sidewalls_dialog_url,
                    info_dialog_url: item.info_dialog_url,
                    download_specification_url: item.download_specification_url,
                    font: this.config.moduleSettings.font,
                    font_size: this.config.moduleSettings.font_size,
                    dimensions: this.config.moduleSettings.dimensions
                };
            if (item.state_position) {
                item.state_position = $.parseJSON(item.state_position);
                options.x = parseInt(item.state_position.x);
                options.y = parseInt(item.state_position.y);
            }

            if (parseInt(item.angular) === CORNER_NONE) {
                module = new LineRectModule(options);
            } else {
                options.type = parseInt(item.angular) === CORNER_IC ? "IC" : "OC";
                options.corner = parseInt(item.angle);
                module = new LineCornerModule(options);
            }
            module.addToCanvas();
            return module;
        };

        /**
         * Загружает данные и добавляет модули
         */
        ConstructorData.prototype.loadData = function () {
            var that = this;
            if (this.xhr !== null) {
                this.xhr.abort();
            }
            this.xhr = $.ajax({
                url: this.config.dataUrl,
                dataType: "json",
                type: "get",
                success: function (response) {
                    that.clearData();
                    that.typeOfEquipment = parseInt(response.typeOfEquipment);
                    $.each(response.modules, function (key, item) {
                        var module = that.addModule(item);
                        if (!item.state_position) {
                            that.addNextPosition(module.getHeight());
                        }
                        that.figures.push(module);
                    });
                    $(document).trigger("ConstructorData::dataLoaded");
                }
            });
        };

        global.ConstructorData = ConstructorData;
    }

}(jQuery, typeof exports !== 'undefined' ? exports : this));