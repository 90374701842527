/**
 * Created by php-developer on 06.10.17.
 */
/*global jQuery, exports, LineModule, utils*/
(function ($, global) {
    "use strict";

    var LineCornerModule;

    if (global.LineCornerModule === undefined) {
        LineCornerModule = function (options) {
            this.config = null;
            this.group = null;
            this.module = null;
            this.text = null;
            this.fabric = null;
            return this.initialize(options);
        };

        LineCornerModule.DEFAULTS = $.extend({}, LineModule.DEFAULTS, {
            corner: 45,
            type: "IC",
            maxTextLength: 15
        });

        LineCornerModule.prototype = $.extend({}, LineModule.prototype);
        LineCornerModule.prototype.constructor = LineCornerModule;

        LineCornerModule.prototype.getDefaults = function () {
            return LineCornerModule.DEFAULTS;
        };

        /**
         * Инициализирует опции модуля
         */
        LineCornerModule.prototype.initializeGroupOptions = function () {
            this.group.setOptions({
                left: this.config.x,
                top: this.config.y,
                snapAngle: this.config.corner === 90 ? 45 : 22.5,
                angle: this.config.angle,
                id: this.getId(),
                module: this
            });
        };

        /**
         * Создаёт модуль
         */
        LineCornerModule.prototype.addModule = function () {
            var figure = "M" + this.config.type + this.config.corner,
                dimensions = this.getDimension(figure);

            this.module = new this.fabric["M" + this.config.type + this.config.corner]($.extend({
                fill: 'rgba(255,255,255,1)',
                stroke: 'rgba(0,0,0,1)'
            }, dimensions));
        };

        /**
         * Позиция текста
         * @returns {number}
         */
        LineCornerModule.prototype.textPosition = function () {
            var point = new this.fabric.Point(0, 0);
            if (this.config.type === "OC") {
                point.setY(10);
            } else {
                point.setY(40);
            }
            return point;
        };

        /**
         * Вид текста для моулей разного типа
         * @returns {string}
         */
        LineCornerModule.prototype.prepareText = function () {
            if (this.config.short_name === null || this.config.short_name.length === 0) {
                if (this.config.corner === 45) {
                    this.config.short_name = this.config.type + " " + this.config.corner;
                } else {
                    this.config.short_name = utils.trimText(this.config.name, this.config.maxTextLength);
                }
            }
            return this.config.short_name;
        };

        global.LineCornerModule = LineCornerModule;
    }

}(jQuery, typeof exports !== 'undefined' ? exports : this));