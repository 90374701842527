/**
 * Created by php-developer on 09.10.17.
 */
/*global jQuery, exports, MOCVerticesCalculator, mathUtils*/
(function ($, global) {

    "use strict";

    var fabric = global.fabric || (global.fabric = {}),
        MOC45VerticesCalculator = function (group, figure) {
            this.group = group;
            this.figure = figure;
        };

    MOC45VerticesCalculator.prototype = $.extend({}, MOCVerticesCalculator.prototype);
    MOC45VerticesCalculator.prototype.constructor = MOC45VerticesCalculator;

    MOC45VerticesCalculator.prototype.calculate = function (params) {
        var vertices = {},
            tl = new fabric.Point(params.coords.x - params.offsetX, params.coords.y - params.offsetY),
            bl = mathUtils.movePointDown(tl, params.currentHeight, params.cosTh, params.sinTh);

        vertices.tl = mathUtils.movePointDown(tl, this.figure.append, params.cosTh, params.sinTh);
        vertices.tr = mathUtils.movePointRight(vertices.tl, params.currentWidth, params.cosTh, params.sinTh);
        vertices.bl = mathUtils.movePointRight(bl, (params.currentWidth / 2), params.cosTh, params.sinTh);
        vertices.br = new fabric.Point(vertices.bl.x, vertices.bl.y);
        return vertices;
    };

    global.MOC45VerticesCalculator = MOC45VerticesCalculator;

}(jQuery, typeof exports !== "undefined" ? exports : this));