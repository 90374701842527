/**
 * Created by php-developer on 01.08.17.
 */
/*global jQuery, window, ConstructorModulesDisplaySettings*/
(function ($) {
    "use strict";

    if (window.constructorSettingsPage === undefined) {
        window.constructorSettingsPage = {
            initialize: function () {
                var constructorSettings = new ConstructorModulesDisplaySettings();
                constructorSettings.initialize();
            }
        };
    }

}(jQuery));