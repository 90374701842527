/*global mathUtils*/
(function (global) {

    'use strict';

    var fabric = global.fabric || (global.fabric = {});

    if (fabric.MOC90) {
        fabric.warn('fabric.MOC90 is already defined.');
        return;
    }

    /**
     * Triangle class
     * @class fabric.Triangle
     * @extends fabric.Object
     * @return {fabric.Triangle} thisArg
     * @see {@link fabric.Triangle#initialize} for constructor definition
     */
    fabric.MOC90 = fabric.util.createClass(fabric.Object, /** @lends fabric.MOC90.prototype */ {

        type: 'MOC90',

        /**
         * Constructor
         * @param {Object} [options] Options object
         * @return {Object} thisArg
         */
        initialize: function (options) {
            this.callSuper('initialize', options);
            this.set('width', options && options.width ? parseFloat(options.width) : 116.4)
                .set('height', options && options.height ? parseFloat(options.height) : 70.1)
                .set('append', options && options.append ? parseInt(options.append) : 20)
                .set('smallWidth', options && options.smallWidth ? parseFloat(options.smallWidth) : this.width / 14);
        },

        /**
         * @private
         * @param {CanvasRenderingContext2D} ctx Context to render on
         */
        _render: function (ctx) {
            var widthBy2 = this.width / 2,
                heightBy2 = this.height / 2;

            ctx.beginPath();
            ctx.moveTo(-widthBy2, -(heightBy2 - this.append));
            ctx.lineTo(-this.smallWidth, heightBy2);
            ctx.lineTo(this.smallWidth, heightBy2);
            ctx.lineTo(widthBy2, -(heightBy2 - this.append));
            ctx.lineTo(widthBy2 / 2, -heightBy2);
            ctx.lineTo(-widthBy2 / 2, -heightBy2);
            ctx.closePath();

            this._renderFill(ctx);
            this._renderStroke(ctx);
        }

    });

    /**
     * Returns {@link fabric.Triangle} instance from an object representation
     * @static
     * @memberOf fabric.Triangle
     * @param {Object} object Object to create an instance from
     * @param {function} [callback] invoked with new instance as first argument
     */
    fabric.MOC90.fromObject = function (object, callback) {
        return fabric.Object._fromObject('MOC90', object, callback);
    };

})(typeof exports !== 'undefined' ? exports : this);