/*global define, translations, window*/
(function ($) {
    "use strict";

    /**
     * Перевести строку при помощи messageResource
     * @param key
     * @param module
     * @returns {*}
     */
    function translate(key, module) {
        return translations.get(key, module);
    }

    /**
     * Простая плюрализация
     * @param key
     * @param amount
     * @returns {*}
     */
    function pluralize(key, amount) {
        if (amount === undefined) {
            return false;
        }
        if (/(11|12|13|14)$/.exec(amount)) {
            return key + '[many]';
        }
        if (/(2|3|4)$/.exec(amount)) {
            return key + '[few]';
        }
        if (/1$/.exec(amount)) {
            return key + '[one]';
        }
        return key + '[other]';
    }

    /**
     * Парсинг ключа по тоске
     * Возвращает объект {module,key}
     * @param keyName
     * @returns {*}
     */
    function parseKey(keyName) {
        var pluralReg = /\((\d+)\)$/,
            libReg = /^[a-zA-Z0-9_\-]+/,
            keyReg = /(?:\.)[a-zA-Z0-9_\-]+/,
            lib = libReg.exec(keyName),
            key = keyReg.exec(keyName),
            plural;

        if (lib && typeof lib[0] && key && typeof key[0]) {
            key[0] = key[0].replace('.', '');
            plural = pluralReg.exec(keyName);
            if (plural && typeof plural[0]) {
                key[0] = pluralize(key[0], plural[1]);
            }
            return {
                module: lib[0],
                key: key[0]
            };
        }
        return false;
    }

    /**
     * Парсинг переменных в стиле {n}
     * @param string
     * @param variables
     * @returns {*}
     */
    function parseVariables(string, variables) {
        $.each(variables, function (i, variable) {
            string = string.replace(new RegExp('\\{' + i + '\\}', 'g'), variable);
        });
        return string;
    }

    /**
     * Парсинг ключа и передаваемых переменных
     * @param key
     * @param variables
     * @returns {*}
     */
    function get(key, variables) {

        if (typeof key !== 'string') {
            return;
        }

        var namespace = parseKey(key),
            translated;
        if (variables === undefined) {
            variables = [];
        }

        if (key) {
            translated = translate(namespace.key, namespace.module);
            return parseVariables(translated, variables);

        }
        return translated;
    }

    window.i18n = {
        t: get
    };
}(jQuery));