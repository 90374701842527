/**
 * Created by php-developer on 05.09.17.
 */
/*global jQuery, define, require, Handlebars, window, scriptPath*/
(function ($) {
    "use strict";

    var Utils = function () {

        function randomString(length) {
            var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz",
                randomstring = '',
                rnum;
            while (length) {
                rnum = Math.floor(Math.random() * chars.length);
                randomstring += chars.substring(rnum, rnum + 1);
                length -= 1;
            }
            return randomstring;
        }

        function getUrlVars() {
            var vars = [],
                hash,
                hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            $.each(hashes, function () {
                hash = this.split('=');
                vars.push(hash[0]);
                vars[hash[0]] = hash[1];
            });
            return vars;
        }

        //Arguments: "name"s of forms to submit.
        //First argument: the form which according to its "action" all other forms will be submitted.
        //Example: mergeForms("form1","form2","form3","form4")
        function mergeForms() {
            var forms = [];
            $.each($.makeArray(arguments), function (index, value) {
                forms[index] = document.forms[value];
            });
            var targetForm = forms[0];
            $.each(forms, function (i, f) {
                if (i !== 0) {
                    $(f).find('input, select, textarea, file')
                        .hide()
                        .appendTo($(targetForm));
                }
            });
            $(targetForm).submit();
        }

        /**
         * Получаем адрес сайта
         */
        function getBaseUrl() {
            var pathArray = window.location.href.split('/'),
                protocol = pathArray[0],
                host = pathArray[2];
            return protocol + '//' + host;
        }

        /**
         * Замена значений в шаблоне по регулярным выражениям
         * @param tpl Шаблон, который необходимо парсить
         * @param values массив значений [exp => replace]
         * @returns {string}
         */
        function renderTemplate(tpl, values) {
            var newStr = String(tpl);
            $.each(values, function (key, value) {
                if (values.hasOwnProperty(key)) {
                    newStr = newStr.replace(new RegExp("({|%7B)" + key + "(}|%7D)", "g"), value);
                }
            });
            return newStr;
        }

        /**
         * Включает большой spinner
         * @param {jQuery|*} object
         * @param {string} text
         * @returns {undefined}
         */
        function addBigSpinner(object, text) {
            var template = '<div class="modal-backdrop spinner"><div class="loader"><div class="loader-note">{text}</div><div class="loader-image"><i class="fa fa-spinner "></i></div></div></div>';
            if (text === undefined) {
                text = "Please wait...";
            }
            object.after(renderTemplate(template, {text: text}));
        }

        /**
         * Выключает большой spinner
         */
        function removeBigSpinner() {
            var $spinner = $('.spinner');
            $spinner.detach();
        }

        /**
         * Включает или выключает большой spinner
         * @param {jQuery|*} object
         * @param {string} text
         * @returns {undefined}
         */
        function toggleBigSpinner(object, text) {
            var $spinner = $('.spinner');
            if ($spinner.length === 0) {
                addBigSpinner(object, text);
            } else {
                removeBigSpinner();
            }
        }

        /**
         * Включает или выключает мини spinner для gridView
         * @param {jQuery|*} object
         * @param {object} offset
         * @returns {undefined}
         */
        function toggleSmallSpinner(object, offset) {
            var template = '<div class="grid-view-loading load-action" style="top:{top}px;left:{left}px;"></div>';
            if (typeof(object) !== "object") {
                object = jQuery(object);
            }
            if (object.siblings('.load-action').length === 0 && offset !== null) {
                object.after(renderTemplate(template, {top: offset.top, left: offset.left}));
            } else {
                object.siblings('.load-action').detach();
            }
        }

        /**
         * Проверка, что выданный по AJAX запрос
         * Является JSON
         * @param {string} str
         * @returns {Boolean|Array|Object}
         */
        function isJson(str) {
            var ob;
            try {
                ob = JSON.parse(str);
            } catch (e) {
                return false;
            }
            return ob;
        }

        /**
         * Парсит из входных данных только HTML
         * Можно указать вывод в виде jQuery объекта или текста (asHtml)
         * @param data
         * @param contentSelector
         * @param asHtml
         * @returns {*}
         */
        function parseHtmlOnly(data, contentSelector, asHtml) {
            var $content,
                html = "";
            if (contentSelector !== undefined) {
                $content = $(data).filter(contentSelector);
                return (asHtml ? $content.html() : $content);
            } else {
                $content = $(data).clone();
                $.each($content, function ($i) {
                    if (this !== undefined && this.tagName !== undefined) {
                        if (this.tagName.toLowerCase() === "script" || this.tagName.toLowerCase() === "link") {
                            $content.splice($i, 1);
                        }
                    }
                });
                if (!asHtml) {
                    return $content;
                }
                $.each($content, function () {
                    html += (this.outerHTML !== undefined ? this.outerHTML : this.data);
                });
                return html;
            }
        }

        /**
         * Обрезать текст и добавить многоточие
         * @param text
         * @param length integer число символов
         * @param addEllipse
         * @returns {string}
         */
        function trimText(text, length, addEllipse) {
            if (text.length > length) {
                var shortText = text.substr(0, length).trim();
                return shortText + (addEllipse ? "..." : "");
            }
            return trimText;
        }

        /**
         * Померять длину и высоту текста в пикселях
         * https://habrahabr.ru/post/56814/
         * @param text
         * @param styles
         * @returns {{width: number, height: number}}
         */
        function textSize(text, styles) {
            var hiddenStyle = "left:-10000px;top:-10000px;height:auto;width:auto;position:absolute;";
            var hiddenDiv = document.createElement('div');
            var rect = {};
            Object.keys(styles).forEach(function (key) {
                hiddenDiv.style[key] = styles[key];
            });
            if (document.all) {
                hiddenDiv.style.setAttribute('cssText', hiddenStyle);
            } else {
                hiddenDiv.setAttribute('style', hiddenStyle);
            }
            hiddenDiv.innerHTML = text;
            parent.document.body.appendChild(hiddenDiv);
            rect = {width: hiddenDiv.clientWidth, height: hiddenDiv.clientHeight};
            parent.document.body.removeChild(hiddenDiv);
            return rect;
        }

        /**
         * Проверка на пустой объект
         * @param obj
         * @returns {boolean}
         */
        function objectEmpty(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object
        }

        this.initialize = function () {
            this.getUrlVars = getUrlVars;
            this.randomString = randomString;
            this.mergeForms = mergeForms;
            this.getBaseUrl = getBaseUrl;
            this.toggleBigSpinner = toggleBigSpinner;
            this.addBigSpinner = addBigSpinner;
            this.removeBigSpinner = removeBigSpinner;
            this.toggleSmallSpinner = toggleSmallSpinner;
            this.renderTemplate = renderTemplate;
            this.isJson = isJson;
            this.parseHtmlOnly = parseHtmlOnly;
            this.trimText = trimText;
            this.objectEmpty = objectEmpty;
            this.textSize = textSize;
        };

        return this.initialize();
    };
    if (window.utils === undefined) {
        window.utils = new Utils();
    }
}(jQuery));