/**
 * Created by hybris-frontend on 13.04.17.
 */
/*global console, jQuery, encodeURI, confirm, clickModalButton, window, addAjaxScripts, hbs, i18n*/
(function ($) {
    "use strict";
    if (typeof window.DynamicLoadingForm !== "function") {
        window.DynamicLoadingForm = function (options) {
            var config = {
                    dynamicFormSelector: '.dynamic-loading-form', //#EquipmentDialogForm
                    rowElementSelector: '.category-row-elements',
                    elementCountSelector: '.category-row-count',
                    postArrayName: 'elements',
                    //validator settings
                    errorWrapClassName: '.error-wrapper',
                    countOfElementsSelector: '#CountOfElements',
                    duplicateSelector: '.duplicate',
                    unDuplicateSelector: '.un-duplicate',
                    counterSelector: '.counter',
                    ajaxSubmit: true
                },
                validateNonZero = {},

                _$errorWrapper;

            /**
             * показать ошибки сборки
             */
            function errorFadeIn() {
                _$errorWrapper.fadeIn();
            }

            /**
             * Добавить ошибку сборки
             */
            function addError(error, selector) {
                if (_$errorWrapper.html().length > 0) {
                    error = error + '<br/>';
                }
                if (_$errorWrapper.find(selector + '_em__').length === 0) {
                    _$errorWrapper.append('<span id=\'' + selector.slice(1) + '_em__' + '\'>' + error + '</span>');
                }
            }

            /**
             * Очистить и скрыть ошибки сборки
             */
            function errorFadeOut() {
                _$errorWrapper.fadeOut().html('');
            }

            /**
             * Удалить ошибку сборки
             */
            function removeError(selector) {
                $(selector + '_em__').detach();
                if (_$errorWrapper.html().length === 0) {
                    errorFadeOut();
                }
            }

            /**
             * проверяет массив инпутов на ненулевые значения
             * @param elements []
             * @param error string
             * @param selector string
             * @param errorAddedCallback function
             * @returns {boolean}
             */
            function validateHasZero(elements, error, selector, errorAddedCallback) {
                var hasZero = false;
                $.each(elements, function () {
                    if (!this.val() || this.val().length === 0 || parseInt(this.val()) === 0) {
                        this.addClass('error');
                        addError(error, selector);
                        if (typeof errorAddedCallback === "function") {
                            errorAddedCallback(this);
                        }
                        errorFadeIn();
                        hasZero = true;
                    } else {
                        this.removeClass('error');
                    }
                });
                if (hasZero) {
                    return false;
                }
                removeError(selector);
                return true;
            }

            /**
             * Проверка всех ненулевых значений
             */
            function validateCountOfElements() {
                return validateHasZero(validateNonZero, i18n.t('common.please_set_count_of_elements'), config.countOfElementsSelector);
            }

            /**
             * Сброс списка проверяемых на ноль инпутов
             * @returns {undefined}
             */
            function resetZeroValidator() {
                validateNonZero = {};
                setValidateOptions();
            }
            /**
             * Задаёт хандлер для удаления дублированных записей
             */
            function assignUnDuplicateHandlers() {
                $(config.unDuplicateSelector).off('click.duplicateOption').on('click.duplicateOption', function (e) {
                    e.preventDefault();
                    var $this = $(this);
                    $('#' + $this.data('rowid')).detach();
                    resetZeroValidator();
                    validateCountOfElements();
                });
            }

            /**
             * Добавляет валидатор ненулевых значений к изменению значения
             * количества у подвида
             */
            function addCountOfElementsValidator() {
                $(config.elementCountSelector).off('change.validate').on('change.validate', function () {
                    validateCountOfElements();
                });
            }

            /**
             * Валидатор по изменению инпута
             */
            function setValidateOptions() {
                //Добавить ссылку на инпуты в проверяемые
                $(config.rowElementSelector).each(function (key, elementsInput) {
                    var $elementsInput = $(elementsInput),
                        input = $elementsInput.closest('.row').find(config.elementCountSelector);
                    if (validateNonZero[this.id] === undefined) {
                        if ($elementsInput.val() && $elementsInput.val().length > 0) {
                            validateNonZero[this.id] = input;
                        }
                    }
                });

                $(config.rowElementSelector).off('change.validate').on('change.validate', function () {
                    var $elementsInput = $(this),
                        input = $elementsInput.closest('.row').find(config.elementCountSelector); //Инпут с кол-вом эл-в

                    if (validateNonZero[this.id] === undefined) {
                        if ($elementsInput.val() && $elementsInput.val().length > 0) {
                            validateNonZero[this.id] = input;
                        }
                    } else if (!$elementsInput.val() || $elementsInput.val().length === 0 || parseInt($elementsInput.val()) === 0) {
                        validateNonZero[this.id].removeClass('error');
                        delete validateNonZero[this.id];
                    }
                    validateCountOfElements();
                });
                addCountOfElementsValidator();
            }

            /**
             * Инициализирует каунтеры
             * @param selector
             */
            function initCounters(selector) {
                $(selector).counter({
                    plusButtonClass: 'btn btn-default counter-plus',
                    minusButtonClass: 'btn btn-default counter-minus',
                    plus: '<span class="fa fa-chevron-right"></span>',
                    minus: '<span class="fa fa-chevron-left"></span>',
                    pattern: '[0-9]+',
                    min: 0
                });
                $(selector).off('updated.counter').on('updated.counter', function () {
                    validateCountOfElements();
                });
            }

            /**
             * Обязательно надо ставить [elements][] ,иначе доп элементы не сохраняются.
             * Задаём обработчики кнопок дублирования
             */
            function assignDuplicateEvent() {
                $(config.duplicateSelector).off('click.duplicateOption').on('click.duplicateOption', function (e) {
                    e.preventDefault();
                    var $this = $(this),
                        category_id = $this.data('category-id'),
                        kind = $this.data('kind'),
                        $container = $('#input_container_' + category_id + '_' + kind),
                        $original = $('#elements_input_' + category_id + '_' + kind + '_0'),
                        index = $container.children().length,
                        data = [];

                    $original.children().each(function () {
                        var $that = $(this);
                        data.push({id: $that.val(), text: $that.html()});
                    });
                    hbs.render('dynamicFormDuplicate.hbs', function (template) {
                        var $element,
                            $duplicate = $(template({
                                category_id: category_id,
                                count: index,
                                kind: kind,
                                postArrayName: config.postArrayName
                            }));
                        $element = $duplicate.find('#elements_input_' + category_id + '_' + kind + '_' + index);
                        $element.select2({width: '100%', data: data, multiple: true});
                        $duplicate.appendTo($container);
                        assignUnDuplicateHandlers();
                        setValidateOptions();
                        initCounters("#" + $duplicate.attr('id') + " " + config.counterSelector);
                    });
                });
                setValidateOptions();
            }

            /**
             * Функция, выполняемая при сабмите
             * всей формы
             */
            function formSubmit(form) {
                var $form = $(form);
                if (validateCountOfElements()) {
                    errorFadeOut();
                    if (config.ajaxSubmit) {
                        $.ajax({
                            url: $form.attr('action'),
                            data: $form.serialize(),
                            dataType: 'json',
                            type: 'post',
                            success: function (response) {
                                $.notify(response.message, {globalPosition: 'top right', className: response.status});
                            }
                        });
                    } else {
                        return true;
                    }
                } else {
                    errorFadeIn();
                }
                return false;
            }

            this.initialize = function () {
                $.extend(config, options);
                _$errorWrapper = $(config.errorWrapClassName);

                //Функция, выполняемая при сабмите всей формы
                $(config.dynamicFormSelector).off('submit.DynamicLoadingForm').on('submit.DynamicLoadingForm', function (e) {
                    return formSubmit(this);
                });

                // событие нажатия на клавиши в форме
                $(config.dynamicFormSelector).keydown(function (e) {
                    if (e.keyCode === 13) {
                        e.preventDefault();
                        return false;
                    }
                });

                assignDuplicateEvent();
                assignUnDuplicateHandlers();
                setValidateOptions();
                initCounters(config.counterSelector);
            };
        };
    }
}(jQuery));