String.prototype.hashCode = function () {
    var hash = 0, i, chr, len;
    if (this.length == 0) return hash;
    for (i = 0, len = this.length; i < len; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
};

/**
 * jQuery функция для перезагрузки страницы в случае передачи
 * Заголовка 401 (неавторизован)
 * @param {type} param
 */
jQuery(document).ajaxError(function (event, jqxhr, settings, exception) {
    if (jqxhr.status === 401) {
        window.location.reload();
    }
});

/**
 * jQuery функция для автоматического скрытия алертов
 * @param {type} param
 */
jQuery(document).ready(function () {
    "use strict";
    jQuery('.alert.fade').delay(5000).fadeOut();
});

/**
 * Удаляет скрипты, добавленные ранее методом addAjaxScripts
 */
function removeAjaxScripts() {
    "use strict";
    dynamicAssetsLoader.removeAjaxScripts();
}

/**
 * Добавляет скрипты в текущий документ
 * updated 21.05.2015 Происходит проверка, был ли уже загружен скрипт
 * Если не был загружен, то загружается. Если был - пропускается.
 * @param {type} data
 * @returns {undefined}
 */
function addAjaxScripts(data) {
    "use strict";
    dynamicAssetsLoader.addAjaxScripts(data);
}

function clickButton(selector, message) {
    jQuery(selector).unbind('click').on('click', function (e) {
        e.preventDefault();
        if (message !== false && !confirm(message)) return false;
        var self = jQuery(this);
        var grid = self.parents('div.grid-view').attr('id');
        utils.toggleSmallSpinner(self, self.offset());
        var url = this.href;
        jQuery.ajax({
            'url': url,
            'dataType': 'json',
            'type': 'POST',
            'success': function (data) {
                if (data.type == 'success') {
                    jQuery.notify(data.data, {globalPosition: 'top right', className: data.type});
                    $.fn.yiiGridView.update(grid);
                }
                utils.toggleSmallSpinner(self, null)
            }
        })
    })
}

function clickModalButton(selector, modalId, message) {
    "use strict";
    var dialog = new ModalDialog({
        trigger: selector,
        message: message,
        modalId: modalId
    });
    dialog.initializeTrigger();
}