/*global define*/
/*global define, require, Handlebars, window, i18n*/
(function ($) {
    "use strict";

    /**
     * Хелпер для переводов
     */
    Handlebars.registerHelper('i18n', function (category, string) {
        return i18n.t(category + '.' + string);
    });

}(jQuery));