/**
 * Created by php-developer on 09.10.17.
 */
/*global MOC45VerticesCalculator, MIC45VerticesCalculator, MOC90VerticesCalculator, MIC90VerticesCalculator, RectVerticesCalculator*/
(function (global) {

    "use strict";

    var fabric = global.fabric || (global.fabric = {}),
        VerticesCalculator = function (group) {
            this.group = group;
            this.figure = null;
            return this.initialize();
        },

        calculators = {
            "MOC45": MOC45VerticesCalculator,
            "MIC45": MIC45VerticesCalculator,
            "MOC90": MOC90VerticesCalculator,
            "MIC90": MIC90VerticesCalculator,
            "rect": RectVerticesCalculator
        };

    /**
     * Инициализация модуля
     */
    VerticesCalculator.prototype.initialize = function () {
        var groupFigures = this.group.getObjects();
        this.figure = groupFigures[0];
        this.calculator = new calculators[this.figure.type](this.group, this.figure);
    };

    /**
     * Подсчёт вершин
     */
    VerticesCalculator.prototype.calculate = function () {
        var params = {
            vpt: this.group.getViewportTransform(),
            theta: fabric.util.degreesToRadians(this.group.angle),
            dim: this.group._calculateCurrentDimensions(),
            center: this.group.getCenterPoint()
        };
        params.currentWidth = params.dim.x;
        params.currentHeight = params.dim.y;
        params.sinTh = Math.sin(params.theta);
        params.cosTh = Math.cos(params.theta);
        params.angle = params.currentWidth > 0 ? Math.atan(params.currentHeight / params.currentWidth) : 0;
        params.hypotenuse = (params.currentWidth / Math.cos(params.angle)) / 2;
        params.offsetX = Math.cos(params.angle + params.theta) * params.hypotenuse;
        params.offsetY = Math.sin(params.angle + params.theta) * params.hypotenuse;
        params.coords = fabric.util.transformPoint(params.center, params.vpt);
        return this.calculator.calculate(params);
    };

    /**
     * Подсчёт корректировки верхней левой вершины
     * @param movingFigureVertices
     * @param snapVerticesNames
     * @param snapVerticesCoords
     * @returns {*}
     */
    VerticesCalculator.prototype.calculateTopLeftPoint = function (movingFigureVertices, snapVerticesNames, snapVerticesCoords) {
        return this.calculator.calculateTopLeftPoint(movingFigureVertices, snapVerticesNames, snapVerticesCoords);
    };

    global.VerticesCalculator = VerticesCalculator;

}(typeof exports !== 'undefined' ? exports : this));