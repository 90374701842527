/**
 * Created by php-developer on 09.10.17.
 */
/*global jQuery, exports, VerticesCalculator, mathUtils*/
(function (global) {

    "use strict";

    var fabric = global.fabric || (global.fabric = {}),
        MICVerticesCalculator = function (group, figure) {
            this.group = group;
            this.figure = figure;
        };

    MICVerticesCalculator.prototype.calculate = function (params) {
        var vertices = {},
            tl = new fabric.Point(params.coords.x - params.offsetX, params.coords.y - params.offsetY),
            bl = mathUtils.movePointDown(tl, params.currentHeight, params.cosTh, params.sinTh),
            mt = mathUtils.movePointRight(tl, (params.currentWidth / 2), params.cosTh, params.sinTh),
            O = mathUtils.movePointUp(mt, (this.figure.radius - this.figure.radiusCorrection), params.cosTh, params.sinTh);

        vertices.tl = mathUtils.rotateArcPointToAngle(O, this.figure.radius, this.figure.endAngle, params.theta);
        vertices.tr = mathUtils.rotateArcPointToAngle(O, this.figure.radius, this.figure.startAngle, params.theta);
        vertices.bl = mathUtils.movePointUp(bl, this.figure.append, params.cosTh, params.sinTh);
        vertices.br = mathUtils.movePointRight(vertices.bl, params.currentWidth, params.cosTh, params.sinTh);
        return vertices;
    };

    /**
     *
     * @param snapZone
     * @returns {*}
     */
    MICVerticesCalculator.prototype.calculateTopLeftPoint = function (snapZone) {
        var A = snapZone.vertices[1],
            C = snapZone.vertices[0],
            BA, BC, AC, snapFigureMediumTLPoint, snapFigureTLPoint,
            theta = fabric.util.degreesToRadians(this.group.angle),
            sinTh = Math.sin(theta),
            cosTh = Math.cos(theta);

        if (snapZone.snapMap.static[1] === "br") {
            BA = this.group.height - mathUtils.lineLength(this.group.oCoords.bl, snapZone.movingVertices.bl); //катет
            BC = mathUtils.lineLength(snapZone.movingVertices.tl, this.group.oCoords.tl); //катет
            AC = mathUtils.lineLength(A, C) - this.group.strokeWidth; //гипотенуза
            snapFigureTLPoint = mathUtils.determineTriangleCorrectionPoint(A, C, BA, BC, AC, false);

            //Стыковка угловых модулей слева к фигуре
        } else {
            BA = mathUtils.lineLength(this.group.oCoords.tr, snapZone.movingVertices.br); //катет
            BC = mathUtils.lineLength(this.group.oCoords.tr, snapZone.movingVertices.tr); //катет
            AC = mathUtils.lineLength(A, C); //гипотенуза
            snapFigureMediumTLPoint = mathUtils.determineTriangleCorrectionPoint(A, C, BA, BC, AC, true);
            snapFigureTLPoint = mathUtils.movePointLeft(snapFigureMediumTLPoint, (this.group.width + this.group.strokeWidth), cosTh, sinTh);
        }

        return snapFigureTLPoint;
    };

    global.MICVerticesCalculator = MICVerticesCalculator;

}(typeof exports !== "undefined" ? exports : this));