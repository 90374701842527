/**
 * Created by php-developer on 20.10.17.
 */
/*global jQuery, window, navbar, interactiveGridRow*/
(function ($) {
    "use strict";

    var config = {
        selector: '.modal-form-ajax-submit'
    };

    function initialize() {
        $(document).off('submit.modalFormAjaxSubmit', config.selector).on('submit.modalFormAjaxSubmit', config.selector, function (e) {
            var $form = $(this);
            $.ajax({
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                type: 'POST',
                success: function (response) {
                    $.notify(response.message, {globalPosition: 'top right', className: response.status});
                }
            });
            return false;
        });
    }

    if (window.modalFormAjaxSubmit === undefined) {
        window.modalFormAjaxSubmit = {
            initialize: initialize
        };
    }

}(jQuery));