/**
 * Created by php-developer on 17.08.17.
 */
/*global window*/
var scriptName = (function () {
    "use strict";
    var scripts = document.getElementsByTagName('script'),
        script = scripts[scripts.length - 1];

    if (script.getAttribute.length !== undefined) {
        return script.getAttribute('src');
    }
    return script.getAttribute('src', 2);
}());
var scriptPath = (function () {
    "use strict";
    var pathArray = (scriptName.indexOf("/") === 0 ? scriptName.substr(1) : scriptName).split('/'),
        path = "",
        i = 0;
    for (i = 0; i < pathArray.length - 1; i += 1) {
        path += "/";
        path += pathArray[i];
    }
    return path;
}());