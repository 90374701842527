/*global mathUtils*/
(function (global) {

    'use strict';

    var fabric = global.fabric || (global.fabric = {});

    if (fabric.MIC90) {
        fabric.warn('fabric.MIC90 is already defined.');
        return;
    }

    /**
     * Triangle class
     * @class fabric.Triangle
     * @extends fabric.Object
     * @return {fabric.Triangle} thisArg
     * @see {@link fabric.Triangle#initialize} for constructor definition
     */
    fabric.MIC90 = fabric.util.createClass(fabric.Object, /** @lends fabric.MIC90.prototype */ {

        type: 'MIC90',

        /**
         * Constructor
         * @param {Object} [options] Options object
         * @return {Object} thisArg
         */
        initialize: function (options) {
            this.callSuper('initialize', options);
            this.set('width', options && options.width ? parseFloat(options.width) : 112.4)
                .set('height', options && options.height ? parseFloat(options.height) : 68.8)
                .set('radius', options && options.radius ? parseInt(options.radius) : 30)
                .set('radiusCorrection', options && options.radiusCorrection ? parseInt(options.radiusCorrection) : 0)
                .set('append', options && options.append ? parseInt(options.append) : 20)
                .set('startAngle', options && options.startAngle ? options.startAngle * Math.PI : 0.43 * Math.PI)
                .set('endAngle', options && options.endAngle ? options.endAngle * Math.PI : 0.57 * Math.PI);
        },

        /**
         * @private
         * @param {CanvasRenderingContext2D} ctx Context to render on
         */
        _render: function (ctx) {
            var widthBy2 = this.width / 2,
                heightBy2 = this.height / 2;

            ctx.beginPath();
            ctx.moveTo(widthBy2, heightBy2 - this.append);
            ctx.arc(0, -heightBy2 - this.radius, this.radius, this.startAngle, this.endAngle, false);
            ctx.lineTo(-widthBy2, heightBy2 - this.append);
            ctx.lineTo(-widthBy2 / 2, heightBy2);
            ctx.lineTo(widthBy2 / 2, heightBy2);
            ctx.closePath();

            this._renderFill(ctx);
            this._renderStroke(ctx);
        }
    });

    /**
     * Returns {@link fabric.Triangle} instance from an object representation
     * @static
     * @memberOf fabric.Triangle
     * @param {Object} object Object to create an instance from
     * @param {function} [callback] invoked with new instance as first argument
     */
    fabric.MIC90.fromObject = function (object, callback) {
        return fabric.Object._fromObject('MIC90', object, callback);
    };

})(typeof exports !== 'undefined' ? exports : this);