/**
 * Created by php-developer on 09.10.17.
 */
/*global jQuery, exports, MOCVerticesCalculator, mathUtils*/
(function ($, global) {

    "use strict";

    var fabric = global.fabric || (global.fabric = {}),
        MOC90VerticesCalculator = function (group, figure) {
            this.group = group;
            this.figure = figure;
        };

    MOC90VerticesCalculator.prototype = $.extend({}, MOCVerticesCalculator.prototype);
    MOC90VerticesCalculator.prototype.constructor = MOC90VerticesCalculator;

    MOC90VerticesCalculator.prototype.calculate = function (params) {
        var vertices = {},
            tl = new fabric.Point(params.coords.x - params.offsetX, params.coords.y - params.offsetY),
            bl = mathUtils.movePointDown(tl, params.currentHeight, params.cosTh, params.sinTh);

        vertices.tl = mathUtils.movePointDown(tl, this.figure.append, params.cosTh, params.sinTh);
        vertices.tr = mathUtils.movePointRight(vertices.tl, params.currentWidth, params.cosTh, params.sinTh);
        vertices.bl = mathUtils.movePointRight(bl, (this.figure.smallWidth * 6), params.cosTh, params.sinTh);
        vertices.br = mathUtils.movePointRight(bl, (this.figure.smallWidth * 8), params.cosTh, params.sinTh);
        return vertices;
    };

    global.MOC90VerticesCalculator = MOC90VerticesCalculator;

}(jQuery, typeof exports !== "undefined" ? exports : this));