/**
 * Created by php-developer on 23.10.17.
 */
/*global jQuery, window, navbar, interactiveGridRow*/
(function ($) {
    "use strict";

    /**
     * Определяет соседние с указанным модули и их положение.
     * Положение проверяется по координатам стыковки
     * Следующий модуль - это следующий по часовой стрелке модуль,
     * входящий в зону стыковки
     * Предыдущий - то же самое против часовой стрелки
     */
    if (window.NeighboursDeterminator === undefined) {
        var NeighboursDeterminator = function () {
            this.neighbours = [];
            this.snapZones = null;
        };

        /**
         * Варианты позиций статического модуля
         * @type {{}}
         */
        NeighboursDeterminator.positions = {
            mbbrbltl: "prev",
            trbrblmb: "prev",
            brblblbr: "prev",
            trbrtlbl: "prev",
            mbblbrtr: "next",
            tlblbrmb: "next",
            tlbltrbr: "next"
        };

        NeighboursDeterminator.prototype.initialize = function () {
            this.collectNeighbours();
        };

        /**
         * Получаем "следующий" модуль
         * @returns {Array|null}
         */
        NeighboursDeterminator.prototype.getNeighbours = function () {
            return this.neighbours;
        };

        /**
         * Установка задействованных зон стыковки текущего модуля
         * @param snapZones
         * @returns {string}
         */
        NeighboursDeterminator.prototype.setSnapZones = function (snapZones) {
            this.snapZones = snapZones;
        };

        /**
         * Ищем ближайших соседей,
         * определяем с какой стороны они добавлены к текущему модулю
         */
        NeighboursDeterminator.prototype.collectNeighbours = function () {
            var zones = this.snapZones,
                that = this;

            $.each(zones, function (i, zone) {
                var variant = zone.snapMap.static.join("") + zone.snapMap.moving.join("");

                if (NeighboursDeterminator.positions.hasOwnProperty(variant) && zone.staticFigure.id !== undefined) {
                    that.neighbours.push({
                        snapMap: variant,
                        module_id: zone.staticFigure.id,
                        position: NeighboursDeterminator.positions[variant]
                    });
                } else {
                    console.error("unknown position " + variant);
                }
            });
        };

        window.NeighboursDeterminator = NeighboursDeterminator;
    }

}(jQuery));