/**
 * Created by php-developer on 08.11.17.
 */
/*global jQuery, exports, Sidewall, mathUtils, utils*/
(function ($, global) {

    "use strict";

    var fabric = global.fabric || (global.fabric = {}),
        RectSidewall = function (group, figure) {
            this.group = group;
            this.figure = figure;
            this.config = null;
        };

    RectSidewall.prototype = $.extend({}, Sidewall.prototype);
    RectSidewall.prototype.constructor = RectSidewall;

    /**
     * Добавляем боковины
     * Если группа обновляется после её создания, то
     * Необходимо учитывать, что все координаты в группе отсчитываются от её центра
     */
    RectSidewall.prototype.addSidewalls = function () {
        var top = -this.group.height / 2,
            textSize = {width: 0, height: 0};

        if (this.group.module.config.left_sidewall !== null && this.group.module.config.left_sidewall !== undefined) {
            this.group.add(new fabric.Rect({
                left: -this.figure.width / 2,
                top: top,
                width: 5,
                height: this.group.height,
                fill: 'rgba(255,255,255,1)',
                stroke: 'rgba(0,0,0,1)',
                name: 'left_sidewall'
            }));
            textSize = this.calculateTextSize(this.group.module.config.left_sidewall);
            this.group.add(new fabric.Textbox(this.group.module.config.left_sidewall, {
                left: -this.figure.width / 2,
                top: -this.group.height / 2 - textSize.height,
                fontSize: this.config.font_size,
                fontFamily: this.config.font,
                name: 'left_sidewall'
            }));
        }
        if (this.group.module.config.right_sidewall !== null && this.group.module.config.right_sidewall !== undefined) {
            this.group.add(new fabric.Rect({
                left: this.figure.width / 2 - 5,
                top: top,
                width: 5,
                height: this.group.height,
                fill: 'rgba(255,255,255,1)',
                stroke: 'rgba(0,0,0,1)',
                name: 'right_sidewall'
            }));
            textSize = this.calculateTextSize(this.group.module.config.right_sidewall);
            this.group.add(new fabric.Textbox(this.group.module.config.right_sidewall, {
                left: this.figure.width / 2 - textSize.width,
                top: -this.figure.height / 2 - textSize.height,
                fontSize: this.config.font_size,
                fontFamily: this.config.font,
                name: 'right_sidewall'
            }));
        }
        if (this.group.module.config.connection !== null && this.group.module.config.connection !== undefined) {
            textSize = this.calculateTextSize(this.group.module.config.connection);
            this.group.add(new fabric.Textbox(this.group.module.config.connection, {
                left: this.figure.width / 2 - textSize.width,
                top: -this.figure.height / 2 - textSize.height,
                fontSize: this.config.font_size,
                fontFamily: this.config.font,
                name: 'right_sidewall'
            }));
        }
        if (this.group.module.config.left_sidewall !== null || this.group.module.config.right_sidewall !== null || this.group.module.config.connection !== null) {
            //this.group.addWithUpdate();
            this.group.set('dirty', true);
            this.group.module.canvas.renderAll();
        }

    };

    global.RectSidewall = RectSidewall;

}(jQuery, typeof exports !== "undefined" ? exports : this));