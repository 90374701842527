/**
 * Created by php-developer on 08.11.17.
 */
/*global jQuery, exports, Sidewall, mathUtils*/
(function ($, global) {

    "use strict";

    var fabric = global.fabric || (global.fabric = {}),
        MIC45Sidewall = function (group, figure) {
            this.group = group;
            this.figure = figure;
        };

    MIC45Sidewall.prototype = $.extend({}, Sidewall.prototype);
    MIC45Sidewall.prototype.constructor = MIC45Sidewall;


    /**
     *
     * @returns {{top: (*|Number), left: (*|Number), angle: number}}
     */
    MIC45Sidewall.prototype.getLeftSidewallPosition = function () {
        var widthBy2 = this.group.width / 2,
            heightBy2 = this.group.height / 2,
            O = new fabric.Point(0, -heightBy2 - this.figure.radius + this.figure.radiusCorrection),
            p1 = mathUtils.rotateArcPointToAngle(O, this.figure.radius, this.figure.endAngle, 0),
            p2 = new fabric.Point(-widthBy2, heightBy2 - this.figure.append);

        return {
            top: p1.y,
            left: p1.x,
            angle: fabric.util.radiansToDegrees(Math.PI / 2 + mathUtils.lineAngle(p1, p2)),
            height: mathUtils.lineLength(p1, p2)
        };
    };

    /**
     *
     * @returns {{top: (*|Number), left: (*|Number), angle: number}}
     */
    MIC45Sidewall.prototype.getRightSidewallPosition = function () {
        var widthBy2 = this.group.width / 2,
            heightBy2 = this.group.height / 2,
            O = new fabric.Point(0, -heightBy2 - this.figure.radius + this.figure.radiusCorrection),
            p1 = mathUtils.rotateArcPointToAngle(O, this.figure.radius, this.figure.startAngle, 0),
            p2 = new fabric.Point(widthBy2, heightBy2 - this.figure.append),
            angle = mathUtils.lineAngle(p1, p2),
            cos = Math.cos(angle),
            sin = Math.sin(angle),
            p3 = mathUtils.movePointDown(p1, 5, cos, sin);

        return {
            top: p3.y,
            left: p3.x,
            angle: -fabric.util.radiansToDegrees(Math.PI / 2 - angle),
            height: mathUtils.lineLength(p1, p2)
        };
    };

    global.MIC45Sidewall = MIC45Sidewall;

}(jQuery, typeof exports !== "undefined" ? exports : this));