/**
 * Created by php-developer on 13.10.17.
 */
/*global jQuery, exports, ModalDialog*/
(function ($, global) {
    "use strict";

    if (global.ModuleContextMenuActions === undefined) {

        var ModuleContextMenuActions = function () {
            this.ctxTarget = null;
            this.canvas = null;
        };

        /**
         * Инициализирует модальное окно
         * @param url
         * @param modalId
         * @param callback
         */
        ModuleContextMenuActions.prototype.initializeModal = function (url, modalId, callback) {
            var dialog = new ModalDialog({
                url: url,
                modalId: modalId
            });
            dialog.getDialogContent();
            $(document).off('removed.bs.modal').on('removed.bs.modal', function (e, data) {
                if (data.id === modalId) {
                    $("#" + modalId).find('select.select2-offscreen').each(function (index, item) {
                        $(item).select2('destroy');
                    });
                    if (typeof callback === "function") {
                        callback(modalId);
                    }
                }
            });
        };

        /**
         * Удаляет модуль из канваса и из базы
         */
        ModuleContextMenuActions.prototype.deleteModule = function () {
            var that = this;
            if (this.ctxTarget.module !== undefined) {
                $.ajax({
                    url: that.ctxTarget.module.config.remove_url,
                    type: 'post',
                    dataType: "json",
                    success: function (data) {
                        if (data.status === "success") {
                            that.canvas.remove(that.ctxTarget);
                        }
                        if (data.message) {
                            $.notify(data.message, {globalPosition: 'top right', className: data.status});
                        }
                    }
                });
            }
        };

        /**
         * Открывает модальное окно опций модуля
         */
        ModuleContextMenuActions.prototype.startOptionsDialog = function () {
            if (this.ctxTarget.module !== undefined) {
                this.initializeModal(this.ctxTarget.module.config.options_dialog_url, "options_dialog");
            }
        };

        /**
         * Открывает модальное окно комплектации модуля
         */
        ModuleContextMenuActions.prototype.startEquipmentDialog = function () {
            if (this.ctxTarget.module !== undefined) {
                this.initializeModal(this.ctxTarget.module.config.equipment_dialog_url, "equipment_dialog");
            }
        };

        ModuleContextMenuActions.prototype.startPaintingDialog = function () {
            if (this.ctxTarget.module !== undefined) {
                this.initializeModal(this.ctxTarget.module.config.painting_dialog_url, "painting_dialog");
            }
        };

        ModuleContextMenuActions.prototype.startConnectionTypesDialog = function () {
            if (this.ctxTarget.module !== undefined) {
                this.initializeModal(this.ctxTarget.module.config.connection_types_dialog_url, "connection_types_dialog");
            }
        };

        ModuleContextMenuActions.prototype.startSidewallsDialog = function () {
            var callback = function () {
                $(document).trigger("ModuleContextMenuActions::hiddenSidewallDialog");
            };
            if (this.ctxTarget.module !== undefined) {
                this.initializeModal(this.ctxTarget.module.config.sidewalls_dialog_url, "sidewalls_dialog", callback);
            }
        };

        ModuleContextMenuActions.prototype.startInfoDialog = function () {
            if (this.ctxTarget.module !== undefined) {
                this.initializeModal(this.ctxTarget.module.config.info_dialog_url, "information_dialog");
            }
        };

        ModuleContextMenuActions.prototype.downloadSpecification = function () {
            if (this.ctxTarget.module !== undefined) {
                window.location.href=this.ctxTarget.module.config.download_specification_url;
            }
        };

        /**
         * Устанавливает канвас
         * @param canvas
         */
        ModuleContextMenuActions.prototype.setCanvas = function (canvas) {
            this.canvas = canvas;
        };


        /**
         * Устанавливает контекст
         * @param ctxTarget
         */
        ModuleContextMenuActions.prototype.setContextTarget = function (ctxTarget) {
            this.ctxTarget = ctxTarget;
        };

        /**
         * Обнуляет контекст
         */
        ModuleContextMenuActions.prototype.unsetContextTarget = function () {
            this.ctxTarget = null;
        };

        global.ModuleContextMenuActions = ModuleContextMenuActions;
    }

}(jQuery, typeof exports !== 'undefined' ? exports : this));