/**
 * Created by php-developer on 20.10.17.
 */
/*global jQuery, window, navbar, interactiveGridRow*/
(function ($) {
    "use strict";

    if (window.SaveForEqualsWidget === undefined) {
        var config = {
                checkboxSelector: '#save_for_equals',
                listSelector: '#equal_modules'
            },
            SaveForEqualsWidget = function () {
                this.initialize();
            };

        SaveForEqualsWidget.prototype.initialize = function () {
            $(document).off('change.SaveForEqualsWidget', config.checkboxSelector)
                .on('change.SaveForEqualsWidget', config.checkboxSelector, function (e) {
                    var $suggest = $(config.listSelector);
                    if ($(this).is(':checked')) {
                        $suggest.select2("readonly", false);

                    } else {
                        $suggest.val(null).trigger('change');
                        $suggest.select2("readonly", true);
                    }
                });
        };

        window.SaveForEqualsWidget = SaveForEqualsWidget;
    }

}(jQuery));