/**
 * Created by php-developer on 12.09.17.
 */
/*global jQuery, window, navbar, ImportProcess*/
(function ($) {
    "use strict";

    var config = {
        progressBarRowSelector: '#progressbar_row'
    };

    function initialize() {
        var $progressBarRow = $(config.progressBarRowSelector),
            importProcess = new ImportProcess($progressBarRow.data("options"));
        importProcess.initialize();
    }

    if (window.importProcessPage === undefined) {
        window.importProcessPage = {
            initialize: initialize
        };
    }

}(jQuery));