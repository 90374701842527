/**
 * Created by hybris-frontend on 21.04.17.
 */
/*global console, jQuery, encodeURI, confirm, clickModalButton, window, addAjaxScripts*/
(function ($) {
    "use strict";
    if (typeof window.PaintingFormWidget !== "function") {
        window.PaintingFormWidget = function () {
            var TYPE_MODEL_PART = 3,
                config = {
                    paintingFormWidgetSelector: "#painting_form_widget",
                    paintingItemContainerSelector: '.painting-container',
                    modulePaintingContainerSelector: "#module_painting_container",
                    paintingInputGroupSelector: '.painting-input-group',
                    partialPaintingItemCheckboxSelector: '.color-input-toggle',
                    innerColorMarkupSelector: '.inner-color-markup',
                    outerColorMarkupSelector: '.outer-color-markup',
                    colorMarkupSelector: '.color-markup',
                    colorSuggestSelector: '.color-suggest',
                    colorMarkupUrl: ""
                };

            /**
             *
             * @param markup
             * @param kind
             */
            function updateModelMarkup(markup, kind) {
                var markupSelector = config[kind + "ColorMarkupSelector"],
                    $markupInput = $(config.modulePaintingContainerSelector).find(markupSelector),
                    $colorInputToggle = $(config.modulePaintingContainerSelector).find(config.partialPaintingItemCheckboxSelector);

                if (!$colorInputToggle.is(':checked')) {
                    $colorInputToggle.prop("checked", true);
                    $colorInputToggle.attr("checked", true);
                    $colorInputToggle.trigger("change.paintingItemCheckbox.PaintingBlock");
                }
                if (markup > $markupInput.val()) {
                    $markupInput.val(markup);
                }
            }

            /**
             * Запрос и установка наценки за покраску в соответствующее поле
             */
            function getColorMarkup() {
                var $colorSuggest = $(this),
                    $paintingInputGroup = $colorSuggest.parents(config.paintingInputGroupSelector),
                    $markupInput = $paintingInputGroup.find(config.colorMarkupSelector),
                    colorMarkupUrl = $paintingInputGroup.data('color-markup-url') || config.colorMarkupUrl,
                    data = {
                        color_id: $colorSuggest.val(),
                        type: $colorSuggest.data('type'),
                        kind: $colorSuggest.data('kind')
                    };

                if (!data.color_id || data.color_id.length === 0) {
                    return;
                }

                $.ajax({
                    url: colorMarkupUrl,
                    type: 'GET',
                    dataType: 'json',
                    data: data,
                    success: function (response) {
                        $markupInput.val(response);
                        if (parseInt(data.type) === TYPE_MODEL_PART) {
                            updateModelMarkup(response, data.kind);
                        }
                    }
                });
            }

            /**
             * Колбек для установки значения по умолчанию в поля
             */
            function inputSetDefaultValueCallback() {
                var $that = $(this);
                $that.val($that.data('default-value'));
                $that.trigger('change');
            }

            /**
             * Активирует или деактивирует строку для редактирования цвета конкретного элемента
             */
            function togglePartialPaintingItemCheckbox() {
                var $this = $(this),
                    $item = $this.closest(config.paintingItemContainerSelector),
                    $colorInput = $item.find(config.colorSuggestSelector),
                    $markupInput = $item.find(config.colorMarkupSelector);
                if ($this.is(':checked')) {
                    $colorInput.removeAttr('disabled');
                    $markupInput.removeAttr('disabled');
                } else {
                    $colorInput.each(inputSetDefaultValueCallback);
                    $markupInput.each(inputSetDefaultValueCallback);
                    $colorInput.attr('disabled', true);
                    $markupInput.attr('disabled', true);
                }
            }

            this.initialize = function (options) {
                $.extend(config, options);
                $(document).off("change.colorSuggest.PaintingBlock").on("change.colorSuggest.PaintingBlock", config.colorSuggestSelector, getColorMarkup);
                $(document).off("change.paintingItemCheckbox.PaintingBlock").on("change.paintingItemCheckbox.PaintingBlock", config.partialPaintingItemCheckboxSelector, togglePartialPaintingItemCheckbox);
                $(document).on("shown.bs.modal", function (e) {
                    var $modal = $(e.target),
                        $paintingFormWidget = $modal.find(config.paintingFormWidgetSelector);
                    if ($paintingFormWidget.length > 0) {
                        config.colorMarkupUrl = $paintingFormWidget.data('get-markup-url');
                    }
                });
            };
        };
    }
}(jQuery));
