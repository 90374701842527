/**
 * Created by php-developer on 08.11.17.
 */
/*global jQuery, exports, Sidewall, mathUtils*/
(function ($, global) {

    "use strict";

    var fabric = global.fabric || (global.fabric = {}),
        MOC90Sidewall = function (group, figure) {
            this.group = group;
            this.figure = figure;
        };

    MOC90Sidewall.prototype = $.extend({}, Sidewall.prototype);
    MOC90Sidewall.prototype.constructor = MOC90Sidewall;


    /**
     *
     * @returns {{top: (*|Number), left: (*|Number), angle: number}}
     */
    MOC90Sidewall.prototype.getLeftSidewallPosition = function () {
        var widthBy2 = this.group.width / 2,
            heightBy2 = this.group.height / 2,
            p1 = new fabric.Point(-widthBy2, -(heightBy2 - this.figure.append)),
            p2 = new fabric.Point(-this.figure.smallWidth, heightBy2);

        return {
            top: p1.y,
            left: p1.x,
            angle: -fabric.util.radiansToDegrees(mathUtils.lineAngle(p1, p2)),
            height: mathUtils.lineLength(p1, p2)
        };
    };

    /**
     *
     * @returns {{top: (*|Number), left: (*|Number), angle: number}}
     */
    MOC90Sidewall.prototype.getRightSidewallPosition = function () {
        var widthBy2 = this.group.width / 2,
            heightBy2 = this.group.height / 2,
            p1 = new fabric.Point(widthBy2, -(heightBy2 - this.figure.append)),
            p2 = new fabric.Point(this.figure.smallWidth, heightBy2),
            angle = mathUtils.lineAngle(p1, p2),
            cos = Math.cos(angle),
            sin = Math.sin(angle),
            p3 = mathUtils.movePointUp(p1, 5, cos, sin);

        return {
            top: p3.y,
            left: p3.x,
            angle: -fabric.util.radiansToDegrees(angle),
            height: mathUtils.lineLength(p1, p2)
        };
    };

    global.MOC90Sidewall = MOC90Sidewall;

}(jQuery, typeof exports !== "undefined" ? exports : this));