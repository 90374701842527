/*global define, exports, require, jQuery, setTimeout, setInterval, clearInterval, clearTimeout, document*/
/*!
 * Плагин инпута с автоматически добавляемыми кнопками
 * @author rovico
 * Technonikol Numbut Plugin
 */
(function (factory) {
    "use strict";
    if (typeof define === 'function' && define.amd) {
        // AMD
        define(['jquery'], factory);
    } else if (typeof exports === 'object') {
        // CommonJS
        factory(require('jquery'));
    } else {
        // Browser globals
        factory(jQuery);
    }
}(function ($) {
    "use strict";
    var CounterInput = function (element, options) {

        /**
         * Текеущий элемент
         * @type {jQuery}
         */
        this.$element = null;

        /**
         * Селекторы для выбора данных из параметров data
         * @type {{wrapperStyle: string, wrapperClass: string}}
         */
        this.dataSelectors = {
            wrapperStyle: 'wrapper-style',
            wrapperClass: 'wrapper-class'
        };

        /**
         * Шаблоны для генерации контролов и враппера
         * @type {{wrapper: string, controls: string}}
         */
        this.templates = {
            wrapper: '<div class="{wrapperCustomClass}" style="{wrapperCustomStyle}"></div>',
            controls: '<a class="{plusButtonClass}" href="#">{plus}</a><a class="{minusButtonClass}" href="#">{minus}</a>'
        };

        /**
         * Запуск инициализации плагина
         */
        this.init(element, options);
    };


    /**
     * Инициализация намбата,
     * Создание контролов
     * @param selector
     * @param options
     */
    CounterInput.prototype.init = function (selector, options) {

        this.options = $.extend({
            step: 1,
            min: 1,
            value: 1,
            pattern: '[0-9.]+',
            inputClass: 'counter',
            plusButtonClass: 'counter-plus',
            minusButtonClass: 'counter-minus',
            plus: '+', //<span class="icon-plus"></span>
            minus: '-',
            wrapperClass: 'counter-wrapper',
            wrapperStyle: '',
            wrapperHtml: '',
            controlsHtml: '',
            productId: null,
            correctEvent: 'counter.correct',
            maxDecimalDimension: 2
        }, options);

        this.$element = $(selector);

        if (this.$element.attr('pattern')) {
            this.options.pattern = this.$element.attr('pattern');
        }

        this.collectDataValues();

        this.options.value = this.correctManualInput(this.$element.val());

        this.createControls();

        this.bindEvents(selector);

        if (this.$element.attr("readonly") === "readonly") {
            this.deactivate("readonly");
        }
        if (this.$element.attr("disabled") === "disabled") {
            this.deactivate("disabled");
        }
    };

    /**
     * Привязка событий
     */
    CounterInput.prototype.bindEvents = function (selector) {

        var that = this;

        //Событие нажатия клавиш в инпуте
        this.$element.on('keypress.counter', function (e) {
            if (that.checkPressedKey(e)) {
                if (e.which === 13) {
                    $(selector, $(this).parent()).counter('manual');
                }
                return true;
            }
            return false;
        });

        //Событие смены min,step,value извне
        this.$element.on('unitsChanged.counter', function () {
            $(selector, $(this).parent()).counter('updateUnits');
        });

        //Событие увеличения значения
        this.bindClickEvent(selector, this.options.plusButtonClass, 'increase');

        //Событие уменьшения значения
        this.bindClickEvent(selector, this.options.minusButtonClass, 'decrease');

        this.$element.bind('blur.counter', function () {
            $(selector, $(this).parent()).counter('manual');
        });
    };

    /**
     * превращает buttonClass в buttonSelector
     * @param buttonClass
     */
    CounterInput.prototype.generateSelector = function (buttonClass) {
        return "." + buttonClass.replace(/[\s\.]+/g, ".");
    };

    /**
     * Событие клика по кнопке
     * @param counterSelector
     * @param btnCssClass
     * @param handler
     */
    CounterInput.prototype.bindClickEvent = function (counterSelector, btnCssClass, handler) {
        var clickInterval = 0,
            clickTimeout = 0;
        this.$element.siblings(this.generateSelector(btnCssClass)).on('click.counter', function (e) {
            e.preventDefault();
        }).on('mousedown.counter', function (e) {
            e.preventDefault();
            var $target = $(this).parent();
            if (!$(this).hasClass('disabled')) {
                $(counterSelector, $target).counter(handler);
                clickTimeout = setTimeout(function () {
                    clickInterval = setInterval(function () {
                        $(counterSelector, $target).counter(handler);
                    }, 100);
                }, 100);
            }
        }).on('mouseup.counter mouseleave.counter', function () {
            if (clickInterval !== undefined && clickTimeout !== undefined) {
                clearInterval(clickInterval);
                clearTimeout(clickTimeout);
            }
        });
    };

    /**
     * Срабатывание указанного события на текущем элементе
     * @param event
     */
    CounterInput.prototype.riseEvent = function (event) {
        this.$element.trigger(event, {target: this.$element, options: this.options});
    };

    /**
     * Запрет ввода любых символов кроме тех, что указаны в паттерне
     */
    CounterInput.prototype.checkPressedKey = function (e) {
        var exp = new RegExp(this.options.pattern),
            keyCode = e.which,
            char = String.fromCharCode(keyCode);
        return !!(keyCode === 0 || keyCode === 8 || keyCode === 9 || keyCode === 13 || char.match(exp));
    };

    /**
     * Округление до 2 знаков, если значение не целое.
     * Если значение целое, то округление до целого.
     * @param value
     * @param demensions
     * @returns {*}
     */
    CounterInput.prototype.round = function (value, demensions) {
        if (value % 1 === 0) {
            return parseInt(value, 0);
        }
        var rounded = Math.round(value),
            fixed = parseFloat(value).toFixed(demensions);

        return fixed === rounded ? rounded : parseFloat(fixed);
    };

    /**
     * Получение стилей враппера из data свойства текущего counter
     */
    CounterInput.prototype.getWrapperStyle = function () {
        return this.$element.data(this.dataSelectors.wrapperStyle) || '';
    };

    /**
     * Получение css класса враппера из data свойства текущего counter
     */
    CounterInput.prototype.getWrapperClass = function () {
        return this.options.wrapperClass + ' ' + this.$element.data(this.dataSelectors.wrapperClass) || '';
    };

    /**
     * Парсинг строки шаблона
     * @param template
     * @param variables
     * @returns {*}
     */
    CounterInput.prototype.parseTemplate = function (template, variables) {
        $.each(variables, function (key, value) {
            template = template.replace("{" + key + "}", value);
        });
        return template;
    };

    /**
     * parse Wrapper Template
     * @returns {*}
     */
    CounterInput.prototype.parseWrapperTemplate = function () {
        return this.parseTemplate(
            this.templates.wrapper,
            {
                wrapperCustomStyle: this.getWrapperStyle(),
                wrapperCustomClass: this.getWrapperClass()
            }
        );
    };

    /**
     * Parse Controls Template
     * @returns {*}
     */
    CounterInput.prototype.parseControlsTemplate = function () {
        return this.parseTemplate(
            this.templates.controls,
            {
                plus: this.options.plus,
                minus: this.options.minus,
                plusButtonClass: this.options.plusButtonClass,
                minusButtonClass: this.options.minusButtonClass
            }
        );
    };

    /**
     * Создаём контролы
     */
    CounterInput.prototype.createControls = function () {
        if (!this.$element.parent().hasClass(this.options.wrapperClass)) {

            if (this.options.wrapperHtml === '') {
                this.options.wrapperHtml = this.parseWrapperTemplate();
            }
            if (this.options.controlsHtml === '') {
                this.options.controlsHtml = this.parseControlsTemplate();
            }

            this.$element
                .attr('type', 'counter')
                .attr('pattern', this.options.pattern)
                .wrap(this.options.wrapperHtml)
                .parent()
                .append(this.options.controlsHtml);

            if (!this.$element.hasClass(this.options.inputClass)) {
                this.$element.addClass(this.options.inputClass);
            }

            if (this.options.min && this.$element.val().length === 0) {
                this.$element.val(this.options.min);
            }
        }
    };

    /**
     * Обновление динамических значений из аттрибутов data
     */
    CounterInput.prototype.collectDataValues = function () {
        if (this.$element.attr('step')) {
            this.options.step = parseFloat(this.$element.attr('step'));
        }
        if (this.$element.attr('min')) {
            this.options.min = parseFloat(this.$element.attr('min'));
        }
    };

    /**
     * Изменение отображаемого в инпуте значения
     */
    CounterInput.prototype.updateInputValue = function (value) {
        this.$element.val(this.round(value, this.options.maxDecimalDimension));
        this.riseEvent('updated.counter');
    };

    /**
     * Корректировка ручного ввода
     * @param value
     * @param toUpper
     */
    CounterInput.prototype.correctManualInput = function (value, toUpper) {

        if (isNaN(value) || value === "" || parseFloat(value) < this.options.min) {
            value = this.options.min;
            this.riseEvent('minValueTrue.counter');
        } else {
            this.riseEvent('minValueFalse.counter');
        }

        if (typeof value === "string") {
            if (value.indexOf(".") > -1) {
                value = parseFloat(value);
            } else {
                value = parseInt(value, 0);
            }
        }

        var middleResult = value % this.options.step,
            roundedMiddleResult = this.round(middleResult, this.options.maxDecimalDimension);

        if (roundedMiddleResult === 0 && value >= this.options.min) {
            this.options.value = parseFloat(value);
        } else if (toUpper) {
            this.options.value = parseFloat(value) - middleResult + parseFloat(this.options.step);
        } else {
            this.options.value = parseFloat(value) - middleResult;
        }

        if (value !== this.options.value) {
            this.riseEvent('corrected.counter');
        }
        return this.options.value;
    };

    /**
     * Обработка нажатия кнопки +
     */
    CounterInput.prototype.increase = function () {
        this.options.value += this.options.step;
        this.riseEvent('increase.counter');
        this.updateInputValue(this.options.value);
    };

    /**
     * Обработка нажатия кнопки -
     */
    CounterInput.prototype.decrease = function () {
        var count = this.options.value - this.options.step;

        this.riseEvent('decrease.counter');

        if (count >= this.options.min) {
            this.options.value = count;
            this.riseEvent('minValueFalse.counter');
            this.updateInputValue(this.options.value);
        } else {
            this.riseEvent('minValueTrue.counter');
        }
    };

    /**
     * Ручная установка значения
     * @returns {boolean}
     */
    CounterInput.prototype.manual = function () {

        var newTarget = document.activeElement,
            $plusButton = this.$element.parents(this.generateSelector(this.options.wrapperClass)).find(this.generateSelector(this.options.plusButtonClass)),
            $minusButton = this.$element.parents(this.generateSelector(this.options.wrapperClass)).find(this.generateSelector(this.options.minusButtonClass));

        if (newTarget === $plusButton.get(0) || newTarget === $minusButton.get(0)) {
            return false;
        }
        this.options.value = this.correctManualInput(this.$element.val(), true);
        this.$element.trigger('manual.counter', this.options);
        this.updateInputValue(this.options.value);
    };

    /**
     * Внешнее изменение единиц измерения
     * @returns {boolean}
     */
    CounterInput.prototype.updateUnits = function () {
        this.collectDataValues();
        this.options.value = parseFloat(this.$element.val());
    };

    /**
     * Превращает инпут и кнопи в неактивный
     */
    CounterInput.prototype.deactivate = function (method) {
        if (method === undefined) {
            method = 'disabled';
        }
        this.$element.parent(this.generateSelector(this.options.wrapperClass))
            .find(this.generateSelector(this.options.plusButtonClass) + "," + this.generateSelector(this.options.minusButtonClass))
            .addClass('disabled');
        this.$element.attr(method, method);
    };

    /**
     * Превращает инпут и кнопи в активный
     */
    CounterInput.prototype.activate = function () {
        this.$element.parent(this.generateSelector(this.options.wrapperClass))
            .find(this.generateSelector(this.options.plusButtonClass) + "," + this.generateSelector(this.options.minusButtonClass))
            .removeClass('disabled');
        this.$element.removeAttr('disabled');
        this.$element.removeAttr('readonly');
    };

    /**
     * jQuery плагин
     * @param option
     * @returns {*}
     */
    $.fn.counter = function (option) {
        return this.each(function () {
            var $this = $(this),
                data = $this.data('tn.counter'),
                options = typeof option === 'object' && option,
                methods = /increase|decrease|manual|updateUnits|activate|deactivate/;

            if (!data && methods.test(option)) {
                return;
            }

            if (!data) {
                data = new CounterInput(this, options);
                $this.data('tn.counter', data);
            }

            if (typeof option === 'string' && methods.test(option)) {
                data[option]();
            }
        });
    };

}));