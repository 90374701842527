/**
 * Created by php-developer on 09.10.17.
 */
/*global jQuery, exports, MICVerticesCalculator*/
(function ($, global) {

    "use strict";

    var MIC45VerticesCalculator = function (group, figure) {
        this.group = group;
        this.figure = figure;
    };

    MIC45VerticesCalculator.prototype = $.extend({}, MICVerticesCalculator.prototype);
    MIC45VerticesCalculator.prototype.constructor = MIC45VerticesCalculator;
    global.MIC45VerticesCalculator = MIC45VerticesCalculator;

}(jQuery, typeof exports !== "undefined" ? exports : this));