/**
 * Created by php-developer on 06.10.17.
 */
/*global jQuery, exports, navbar, interactiveGridRow, utils, SidewallFabric*/
(function ($, global) {
    "use strict";

    var LineModule;

    if (global.LineModule === undefined) {
        LineModule = function (options) {
            this.config = null;
            this.group = null;
            this.module = null;
            this.text = null;
            this.fabric = null;
            return this.initialize(options);
        };

        LineModule.DEFAULTS = {
            x: 0,
            y: 0,
            id: null,
            angle: 0,
            name: null,
            short_name: null,
            face: false,
            bar: false,
            left_sidewall: null,
            right_sidewall: null,
            connection: null,
            width: null,
            length: null,
            max_temperature: 0,
            min_temperature: 0,
            remove_url: null,
            save_state_url: null,
            options_dialog_url: null,
            equipment_dialog_url: null,
            painting_dialog_url: null,
            connection_types_dialog_url: null,
            sidewalls_dialog_url: null,
            info_dialog_url: null,
            download_specification_url: null,
            font: "helvetica",
            font_size: 10,
            defaultHeight: 70,
            defaultWidth: 140
        };

        LineModule.prototype.getDefaults = function () {
            return LineModule.DEFAULTS;
        };

        LineModule.prototype.getOptions = function (options) {
            options = $.extend({}, this.getDefaults(), options);
            return options;
        };

        LineModule.prototype.setConfig = function (options) {
            this.config = this.getOptions(options);
        };

        LineModule.prototype.initialize = function (options) {
            this.fabric = global.fabric || (global.fabric = {});
            this.setConfig(options);
            this.canvas = global.fabricCanvas || (global.fabricCanvas = new this.fabric.Canvas('canvas'));
            this.sidewall = null;
            this.addModule();
            this.addText();
        };

        /**
         * ID модуля. Если у модуля нет ID, генерируется случайное значение
         */
        LineModule.prototype.getId = function () {
            if (this.config.id === null) {
                this.config.id = utils.randomString(5);
            }
            return this.config.id;
        };

        /**
         * Высота модуля
         * @returns {number}
         */
        LineModule.prototype.getHeight = function () {
            return this.module.height;
        };

        /**
         * Сгруппировать и добавить объекты в канвас
         */
        LineModule.prototype.addToCanvas = function () {
            this.group = new this.fabric.Group([this.module, this.text]);
            this.initializeGroupOptions();
            this.sidewall = new SidewallFabric(this.group, this.module);
            this.sidewall.setConfig({
                font: this.config.font,
                font_size: this.config.font_size
            });
            this.sidewall.initialize();
            this.group.setControlsVisibility({
                tl: false,
                ml: false,
                bl: false,
                tr: false,
                mr: false,
                br: false,
                mt: false,
                mb: false
            });
            this.canvas.add(this.group);
        };

        /**
         * Инициализирует опции модуля
         */
        LineModule.prototype.initializeGroupOptions = function () {
            this.group.setOptions({
                left: this.config.x,
                top: this.config.y,
                snapAngle: 45,
                angle: this.config.angle,
                id: this.getId(),
                module: this
            });
        };

        /**
         * Создаёт модуль
         */
        LineModule.prototype.addModule = function () {
            this.module = new this.fabric.Rect({
                width: parseFloat(this.getDimensionValue('rect', 'width', this.config.defaultWidth)),
                height: parseFloat(this.getDimensionValue('rect', 'height', this.config.defaultHeight)),
                fill: 'rgba(255,255,255,1)',
                stroke: 'rgba(0,0,0,1)'
            });
        };

        /**
         * Получает значение из настроек системы
         * @param type
         * @returns {*}
         */
        LineModule.prototype.getDimension = function (type) {
            if (this.config.dimensions !== undefined && !utils.objectEmpty(this.config.dimensions) && this.config.dimensions[type] !== undefined && !utils.objectEmpty(this.config.dimensions[type])) {
                return this.config.dimensions[type];
            }
        };

        /**
         * Получает значение из настроек системы
         * @param type
         * @param dimension
         * @param fallback
         * @returns {*}
         */
        LineModule.prototype.getDimensionValue = function (type, dimension, fallback) {
            if (this.config.dimensions !== undefined && !utils.objectEmpty(this.config.dimensions) && this.config.dimensions[type] !== undefined && !utils.objectEmpty(this.config.dimensions[type])) {
                return this.config.dimensions[type][dimension];
            }
            return fallback;
        };

        /**
         * Создаёт текст
         * angle: getRandomInt(-10, 10),
         * fill: '#' + getRandomColor(),
         * hasRotatingPoint: true,
         * centerTransform: true
         */
        LineModule.prototype.addText = function () {
            var that = this,
                position = this.textPosition();
            this.text = new this.fabric.Textbox(this.prepareText(), {
                left: position.x,
                top: position.y,
                fontSize: parseFloat(this.config.font_size),
                fontFamily: this.config.font,
                originX: 'left',
                originY: 'top',
                textAlign: 'center',
                width: that.module.width
            });
        };

        /**
         * Позиция текста
         * @returns {number}
         */
        LineModule.prototype.textPosition = function () {
            return new this.fabric.Point(0, 0);
        };

        /**
         * Вид текста для моулей разного типа
         * @returns {string}
         */
        LineModule.prototype.prepareText = function () {
            if (this.config.short_name === null || this.config.short_name.length === 0) {
                return "\n" + this.config.name;
            } else {
                return this.config.short_name;
            }
        };

        global.LineModule = LineModule;
    }

}(jQuery, typeof exports !== 'undefined' ? exports : this));