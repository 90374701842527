/**
 * Created by php-developer on 01.08.17.
 */
/*global jQuery, define, require, window, articles, modelNecessaryAccessories, modelRecommendedAccessories, calculationPage, importProcessPage, constructorPage, calculationEquipmentPage, constructorSettingsPage*/
(function ($) {
    "use strict";

    var pagemasks = {
        '/necessary-accessories': modelNecessaryAccessories,
        '/recommended-accessories': modelRecommendedAccessories,
        '/calc/feed': calculationPage,
        'importProcess': importProcessPage,
        '/constructor/': constructorPage,
        '/constructorSettings/': constructorSettingsPage,
        '/calculationEquipment': calculationEquipmentPage
    };

    $(document).ready(function () {
        $.each(pagemasks, function (mask, pageModule) {
            if (window.location.href.indexOf(mask) !== -1) {
                pageModule.initialize();
            }
        });
    });


}(jQuery));
