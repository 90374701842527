/**
 * Created by php-developer on 21.08.17.
 */
/*global jQuery, window, navbar, interactiveGridRow*/
(function ($) {
    "use strict";

    var config = {
        reinitializableSelect2: '[data-reinit-select2]'
    };

    function initialize() {
        $(document).ajaxComplete(function (event, xhr, settings) {
            $(config.reinitializableSelect2).each(function () {
                var $this = $(this);
                $this.select2({
                    width: $this.data("width")
                });
            });
        });
    }

    if (window.afterAjaxUpdateReinitializer === undefined) {
        window.afterAjaxUpdateReinitializer = {
            initialize: initialize
        };
    }

}(jQuery));