/**
 * Created by hybris-frontend on 08.06.17.
 */
/*global define, require, Handlebars, window, i18n*/
(function ($) {
    "use strict";

    var storage = {
        common: {
            Remove_module: 'Удалить модуль',
            Module_options: 'Переименовать модуль',
            Equipment: 'Комплектация',
            Painting: 'Покраска',
            Connection_types: 'Способы стыковки',
            Sidewalls: 'Боковины',
            Show_info: 'Показать информацию',
            Download_specification: 'Выгрузить в спецификацию xls',
            please_set_count_of_elements: 'Укажите значение в поле количества элементов',
            painting_outer: 'RAL снаружи',
            painting_inner: 'RAL внутри',
            markup_outer: 'Наценка',
            markup_inner: 'Наценка',
            please_wait_spinner: 'Пожалуйста, подождите...'
        }
    };

    /**
     * Файл переводов
     */
    if (window.translations === undefined) {
        window.translations = {
            get: function (key, module) {
                if (storage[module] !== undefined && storage[module][key] !== undefined) {
                    return storage[module][key];
                }
                return key;
            }
        };
    }

}(jQuery));