/**
 * Created by php-developer on 04.10.17.
 */
/*global VerticesCalculator, mathUtils, NeighboursDeterminator, SidewallFabric*/
(function ($, global) {
    "use strict";

    if (global.FabricSnap === undefined) {
        var moduleVerticesCalculators = {},
            canvas,

            TYPE_CASE = 1, // Витрины
            TYPE_MULTIDECK = 2, // Горки
            TYPE_ISLAND = 3, // Бонеты

            /**
             * Конструктор
             * @param fabricCanvas
             * @param type_of_equipment
             * @constructor
             */
            FabricSnap = function (fabricCanvas, type_of_equipment) {
                this.type_of_equipment = type_of_equipment === undefined ? TYPE_CASE : type_of_equipment;
                this.xhr = null;
                canvas = fabricCanvas;
            };

        /**
         * Инициализация
         */
        FabricSnap.prototype.initialize = function () {
            var that = this,
                onChange = function (options) {
                    that.snapMovingGroup(options.target);
                },
                onModified = function (options) {
                    that.saveMovingGroupsPosition(options.target);
                };
            canvas.on({
                'object:moving': onChange,
                'object:rotating': onChange,
                'object:modified': onModified
            });
            $(document).on('ConstructorData::clear', this.cleanupModuleVerticesCalculators);
        };

        /**
         * Проверяет каждую вершину текущей фигуры на вхождение
         * в зону склеивания любой из фигур в канвасе
         * @param movingGroup
         * @param getFirst
         * @returns {[]|{}}
         */
        FabricSnap.prototype.getSnapZones = function (movingGroup, getFirst) {
            var staticGroups = canvas.getObjects(),
                vertices = this.getFigureVerticesCoordinates(movingGroup),
                snapZone = {},
                snapZones = [],
                key;

            if (vertices === undefined) {
                return (getFirst ? snapZone : snapZones);
            }

            for (key in staticGroups) {
                if (staticGroups.hasOwnProperty(key) && staticGroups[key].id !== movingGroup.id) {
                    snapZone = this.getOneSnapZone(staticGroups[key], vertices);
                    if (Object.keys(snapZone).length > 0) {
                        if (getFirst) {
                            return snapZone;
                        } else {
                            snapZones.push(snapZone);
                        }
                    }
                }
            }
            return snapZones;
        };

        /**
         * Получает зону стыковки с одним модулем
         * @param staticGroup
         * @param movingVertices
         * @returns {{}}
         */
        FabricSnap.prototype.getOneSnapZone = function (staticGroup, movingVertices) {
            var snapZone = {},
                staticVertices = this.getFigureVerticesCoordinates(staticGroup);
            if (this.type_of_equipment === TYPE_MULTIDECK || this.type_of_equipment === TYPE_ISLAND) {

                if (this.checkSnapZoneVertices(staticVertices, movingVertices, ["mb", "br"], ["bl", "tl"])) {
                    snapZone = this.populateSnapZone(staticVertices, movingVertices, ["mb", "br"], ["bl", "tl"]);

                } else if (this.checkSnapZoneVertices(staticVertices, movingVertices, ["mb", "bl"], ["br", "tr"])) {
                    snapZone = this.populateSnapZone(staticVertices, movingVertices, ["mb", "bl"], ["br", "tr"]);

                } else if (this.checkSnapZoneVertices(staticVertices, movingVertices, ["tr", "br"], ["bl", "mb"])) {
                    snapZone = this.populateSnapZone(staticVertices, movingVertices, ["tr", "br"], ["bl", "mb"]);

                } else if (this.checkSnapZoneVertices(staticVertices, movingVertices, ["tl", "bl"], ["br", "mb"])) {
                    snapZone = this.populateSnapZone(staticVertices, movingVertices, ["tl", "bl"], ["br", "mb"]);

                } else if (this.checkSnapZoneVertices(staticVertices, movingVertices, ["br", "bl"], ["bl", "br"])) {
                    snapZone = this.populateSnapZone(staticVertices, movingVertices, ["br", "bl"], ["bl", "br"]);
                }
            }
            if (this.checkSnapZoneVertices(staticVertices, movingVertices, ["tr", "br"], ["tl", "bl"])) {
                snapZone = this.populateSnapZone(staticVertices, movingVertices, ["tr", "br"], ["tl", "bl"]);

            } else if (this.checkSnapZoneVertices(staticVertices, movingVertices, ["tl", "bl"], ["tr", "br"])) {
                snapZone = this.populateSnapZone(staticVertices, movingVertices, ["tl", "bl"], ["tr", "br"]);
            }
            if (Object.keys(snapZone).length > 0) {
                snapZone.staticFigure = staticGroup;
            }
            return snapZone;
        };

        /**
         *
         * @param stat
         * @param moving
         * @param staticNames
         * @param movingNames
         * @returns {boolean}
         */
        FabricSnap.prototype.checkSnapZoneVertices = function (stat, moving, staticNames, movingNames) {
            var snapArea = 10;
            return mathUtils.lineLength(moving[movingNames[0]], stat[staticNames[0]]) <= snapArea && mathUtils.lineLength(moving[movingNames[1]], stat[staticNames[1]]) <= snapArea;
        };

        /**
         *
         * @param stat
         * @param moving
         * @param staticNames
         * @param movingNames
         * @returns {*}
         */
        FabricSnap.prototype.populateSnapZone = function (stat, moving, staticNames, movingNames) {
            return {
                vertices: [
                    stat[staticNames[0]],
                    stat[staticNames[1]]
                ],
                movingVertices: moving,
                staticVertices: stat,
                staticFigure: null,
                snapMap: {
                    moving: movingNames,
                    static: staticNames
                }
            };
        };

        /**
         * Определяет координаты верхней левой точки описанного прямоугольника фигуры
         * @param movingGroup
         * @param snapZone
         * @returns {*}
         */
        FabricSnap.prototype.calculateTopLeftPoint = function (movingGroup, snapZone) {
            return moduleVerticesCalculators[movingGroup.id].calculateTopLeftPoint(snapZone);
        };

        /**
         * Получаем координаты реальных вершин фигур
         * @param group
         * @returns {{}}
         */
        FabricSnap.prototype.getFigureVerticesCoordinates = function (group) {
            if (group.id === undefined) {
                return undefined;
            }
            if (moduleVerticesCalculators[group.id] === undefined) {
                moduleVerticesCalculators[group.id] = new VerticesCalculator(group);
            }
            return moduleVerticesCalculators[group.id].calculate();
        };

        /**
         *
         */
        FabricSnap.prototype.cleanupModuleVerticesCalculators = function () {
            moduleVerticesCalculators = {};
        };

        /**
         * DEBUG
         * @param points
         * @param color
         * @param timeout
         * var colors = ["red", "blue", "cyan", "yellow", "black", "magenta"];
         * this.debug(snapFigureTLPoint, colors[Math.floor(Math.random() * 6)]);
         */
        FabricSnap.prototype.debug = function (points, color, timeout) {
            if (color === undefined) {
                color = "green";
            }
            var drawPoints = function () {
                canvas.contextTop.fillStyle = color;
                if (points instanceof Array) {
                    for (var i = 0; i < points.length; i++) {
                        canvas.contextTop.fillRect(points[i].x, points[i].y, 3, 3);
                    }
                } else {
                    canvas.contextTop.fillRect(points.x, points.y, 3, 3);
                }
            };
            if (timeout) {
                setTimeout(drawPoints, timeout);
            } else {
                drawPoints();
            }
        };

        /**
         * Сохраняет позиции всех модулей в перемещаемой группе
         * @param movingGroup
         */
        FabricSnap.prototype.saveMovingGroupsPosition = function (movingGroup) {
            var that = this, left, top;
            movingGroup.setCoords();
            if (movingGroup.module === undefined) {
                movingGroup.forEachObject(function (object) {
                    left = movingGroup.left + movingGroup.width / 2;
                    top = movingGroup.top + movingGroup.height / 2;
                    that.saveModuleInformation(object, left, top);
                });
            } else {
                that.saveModuleInformation(movingGroup);
            }
        };

        /**
         * Приклеивает фигуры друг к другу
         * @param movingGroup
         */
        FabricSnap.prototype.snapMovingGroup = function (movingGroup) {
            var snapFigureTLPoint,
                snapZone;
            movingGroup.setCoords();
            if (movingGroup.module !== undefined) {
                snapZone = this.getSnapZones(movingGroup, true);
                if (Object.keys(snapZone).length > 0) {
                    snapFigureTLPoint = this.calculateTopLeftPoint(movingGroup, snapZone);
                    movingGroup.set({left: snapFigureTLPoint.x, top: snapFigureTLPoint.y});
                }
            }
        };

        /**
         * Отправляет на бек информацию о модуле
         * @param movingGroup
         * @param correctionX
         * @param correctionY
         */
        FabricSnap.prototype.saveModuleInformation = function (movingGroup, correctionX, correctionY) {

            var data,
                neigboursDeterminator;
            neigboursDeterminator = new NeighboursDeterminator();
            neigboursDeterminator.setSnapZones(this.getSnapZones(movingGroup, false));
            neigboursDeterminator.initialize();

            data = {
                position: {
                    x: movingGroup.left + (correctionX ? correctionX : 0),
                    y: movingGroup.top + (correctionY ? correctionY : 0)
                },
                angle: movingGroup.angle,
                coords: {},
                neighbours: neigboursDeterminator.getNeighbours()
            };
            $.each(movingGroup.oCoords, function (key, obj) {
                data.coords[key] = {x: obj.x, y: obj.y};
            });
            if (this.xhr !== null) {
                this.xhr.abort();
            }
            this.xhr = $.ajax({
                type: 'post',
                dataType: 'json',
                data: data,
                url: movingGroup.module.config.save_state_url,
                success: function (response) {
                    $(document).trigger('FabricSnap::moduleSaved', {response: response});
                }
            });
        };

        global.FabricSnap = FabricSnap;
    }

})(jQuery, typeof exports !== 'undefined' ? exports : this);