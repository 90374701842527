/**
 * Created by hybris-frontend on 02.05.17.
 * Не смотря на то, что импорт происходит через асинхронные запросы,
 * После ошибки ничего не произойдёт.
 */
/*global console, jQuery, encodeURI, confirm, clickModalButton, window, alert, BulkRemoveButton*/
(function ($) {
    "use strict";
    if (typeof window.ImportProcess !== "function") {
        window.ImportProcess = function (options) {
            var config = {
                    importUrl: "",                                                      // URL адрес импорта
                    progressMetaInfoUrl: "",                                            // Инфо о прогрессбаре
                    notImportedPreviewUrl: "",                                          // Урл для получения списка не импортированных записей
                    formId: "",                                                         // Префикс для модели (категории или артикулы)
                    startRow: "",                                                       // Строка, с которой следует начинать импорт
                    maxRow: "",                                                         // Максимальное число колонок для импорта
                    chunkSize: "",                                                      // Размер импортируемой порции колонок
                    timeout: 5000,                                                      // Таймаут запроса
                    progressBarRowSelector: '#progressbar_row',
                    progressBarSelector: '#upload_prices_progress_bar>.progress-bar',
                    furtherActionsRowSelector: '#further_actions_row',
                    notImportedPreviewWrapperSelector: ".not-imported-wrapper"
                },

                _uploadFileMetaInfo = {};

            /**
             * Собирает опции для отправки на ajaxImport
             * @returns {{}}
             */
            function buildAjaxImportOptions() {
                var data = {};
                data[config.formId] = {
                    startRow: config.startRow,
                    maxRow: config.maxRow,
                    chunkSize: config.chunkSize
                };
                return data;
            }

            /**
             * Обновляет полосу прогресса
             * @param added
             */
            function updateProgressBar(added) {
                var $progressBar = $(config.progressBarSelector),
                    addedPercents = 0,
                    progressPercents = 0,
                    percents;
                if (added === undefined) {
                    $progressBar.css({width: '100%'});
                } else {
                    if (_uploadFileMetaInfo.heighestRow !== undefined) {
                        addedPercents = (added * 100) / _uploadFileMetaInfo.heighestRow;
                    }
                    progressPercents = ($progressBar.width() * 100) / $progressBar.offsetParent().width();
                    percents = addedPercents + progressPercents + '%';
                    $progressBar.css({width: percents});
                }
            }

            /**
             * Автоматически повторяемый импорт
             */
            function repeat_import() {
                $.ajax({
                    url: config.importUrl,
                    data: buildAjaxImportOptions(),
                    type: 'POST',
                    timeout: config.timeout,
                    success: function (data) {
                        if (parseInt(data, 0) === -1) {
                            updateProgressBar();
                            $(document).trigger("importComplete.ImportProcess");
                            $.notify("Импорт завершён успешно!", {globalPosition: 'top right', className: "success"});
                        } else {
                            if (parseInt(data, 0) === 0) {
                                data = config.chunkSize;
                            }
                            updateProgressBar(data);
                            repeat_import();
                        }
                    },
                    complete: function (xhr, textStatus) {
                        if (textStatus !== 'success') {
                            updateProgressBar();
                            $.notify("При импорте возникли ошибки, см. лог сервера", {globalPosition: 'top right', className: "error"});
                        }
                    }
                });
            }

            /**
             * Обновляет информацию о неимпортированных записях
             */
            function updateNotImportedInformation() {
                if (config.notImportedPreviewUrl.length !== 0) {
                    $.ajax({
                        url: config.notImportedPreviewUrl,
                        type: 'POST',
                        success: function (data) {
                            $(config.notImportedPreviewWrapperSelector).html(data);
                        }
                    });
                }
            }

            /**
             * Показать кнопки дальнейших действий и скрыть прогрессбар
             */
            function importCompleteActions() {
                jQuery(config.progressBarRowSelector).hide();
                jQuery(config.furtherActionsRowSelector).show();
                updateNotImportedInformation();
            }

            /**
             * Установка полосы прогресса
             */
            function setProgressBar() {
                jQuery.ajax({
                    url: config.progressMetaInfoUrl,
                    async: 'false',
                    type: 'get',
                    dataType: 'json',
                    timeout: config.timeout,
                    success: function (data) {
                        if (parseInt(data, 0) !== 0) {
                            _uploadFileMetaInfo = data;
                            repeat_import();
                        }
                    }
                });
            }

            this.initialize = function () {
                $.extend(config, options);
                setProgressBar();

                $(document).on("importComplete.ImportProcess", importCompleteActions);
            };
        };
    }
}(jQuery));