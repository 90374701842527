/**
 * Created by hybris-frontend on 13.04.17.
 */
/*global console, jQuery, encodeURI, confirm, clickModalButton, window, addAjaxScripts, modal, utils, hbs, Handlebars*/
(function ($) {
    "use strict";
    if (typeof window.ArticlesSearchForm !== "function") {
        window.ArticlesSearchForm = function () {

            var config = {
                    articlesFormSelector: '#Articles-form',
                    resultsGridSelector: '#Articles-grid',
                    modalSelector: 'div.modal',
                    resultsContainerSelector: '#results_container',
                    parsingResultContent: '#ajax-content',
                    highlightSelector: '.highlight',
                    addToCalculationSelector: '.articles-search-result-grid .add-element',
                    additionalElementsToMask: '#additional_elements_to_{id}',
                    additionalElementsListMask: '#additional_elements_list_{id}',
                    addedRowsMask: 'tr[data-row-category-id="{category_id}"]',
                    countinputSelector: 'input.count',
                    removeAdditionalElementSelector: '.additional-elements .remove-additional-element',
                    removeInputSelector: 'input[name$=\\\[remove\\\]]',
                    alertExistsSelector: '.alert-exists',
                    alertAddedSelector: '.alert-added',
                    counterInputSelector: '.counter',
                    postArrayName: 'elements',
                    templateName: 'articlesSearchForm.hbs',
                    removeRows: false //Удалять строку когда нажимаем кнопку минуса, а не скрывать
                },

                _$resultGrid,

                _renderedTemplateCallback,

                _prepareDataCallback,

                _existsMessageTemplate = "<span class='alert alert-exists alert-mini'><small>Уже есть{{#if totalElementsByValue}} ({{totalElementsByValue}}){{/if}}</small></span>",

                _addMessageTemplate = "<span class='alert alert-success alert-added alert-mini' style='position:absolute'><small>Добавлено{{#if count}} ({{count}}){{/if}}</small></span>";

            /**
             * Инициализирует каунтеры
             * @param selector
             */
            function initCounters(selector) {
                $(selector).counter({
                    plusButtonClass: 'btn btn-default counter-plus',
                    minusButtonClass: 'btn btn-default counter-minus',
                    plus: '<span class="fa fa-chevron-right"></span>',
                    minus: '<span class="fa fa-chevron-left"></span>',
                    pattern: '[0-9]+',
                    min: 0
                });
            }

            /**
             * Устанавливает _$resultGrid
             */
            function setResultGrid() {
                _$resultGrid = $(config.resultsGridSelector);
            }

            /**
             * Получаем родителя из data-параметров формы
             */
            function getResultGridGroupId() {
                return _$resultGrid.data("parent-id");
            }

            /**
             * Получить артикул из data-параметров формы
             */
            function getArticles() {
                return _$resultGrid.data("search");
            }

            /**
             * Генерирует Id для враппера
             * @param groupId
             * @returns {string}
             */
            function buildWrapperId(groupId) {
                return utils.renderTemplate(config.additionalElementsToMask, {'id': groupId});
            }

            /**
             * Генерирует Id для враппера
             * @param groupId
             * @returns {string}
             */
            function buildTbodyId(groupId) {
                return utils.renderTemplate(config.additionalElementsListMask, {'id': groupId});
            }

            /**
             * Подсвечивает указанный фрагмент
             * @param fragment
             * @param element
             */
            function highlightFragment(fragment, element) {
                if (fragment.length > 0) {
                    var $this = $(element),
                        query = new RegExp("(" + fragment + ")", "gi"),
                        content = $this.html(),
                        highlighted = content.replace(query, "<span style='background-color:yellow;'>$1</span>");
                    $this.html(highlighted);
                }
            }

            /**
             * Обработка подсветки значений
             */
            function processHighLights() {
                $(config.highlightSelector).each(function (i, element) {
                    var articles = getArticles();
                    if (Array.isArray(articles)) {
                        $.each(articles, function () {
                            highlightFragment(this, element);
                        });
                    } else if (typeof articles === "string") {
                        highlightFragment(articles, element);
                    }
                });
            }

            /**
             * Показ уведомления при добавлении элемента в расчёт из результатов поиска
             * @param $element
             * @param count
             * @param totalElementsByValue
             */
            function showAlert($element, count, totalElementsByValue) {
                var offset = $element.offset(),
                    left = offset.left + $element.outerWidth() + 5,
                    existsMessageTemplate = Handlebars.compile(_existsMessageTemplate),
                    addMessageTemplate = Handlebars.compile(_addMessageTemplate);

                $element.parent().find(config.alertExistsSelector).remove();
                $element.after($(existsMessageTemplate({totalElementsByValue: totalElementsByValue})));
                $element.after($(addMessageTemplate({count: count})));
                $element.next('.alert').offset({top: offset.top, left: left});
                $element.next('.alert').delay(2000).fadeOut();
                setTimeout(function () {
                    $element.next(config.alertAddedSelector).remove();
                }, 3000);
            }

            /**
             * Обработка нажатия на удаление добавленных элементов
             */
            function removeAdditionalElement($target) {
                var id = $target.data('element'),
                    $row = $('#' + id),
                    groupId = $row.data('parent-id'),
                    $wrapperId = $(buildWrapperId(groupId));
                $row.find(config.removeInputSelector).val(1);
                if (config.removeRows) {
                    $row.remove();
                } else {
                    $row.hide();
                }
                if ($(buildTbodyId(groupId)).find('tr:visible').length === 0) {
                    $wrapperId.hide();
                }
            }

            /**
             * Вызывает колбек для подготовки данных если он был установлен
             * @param data
             * @returns {*}
             */
            function risePrepareDataCallback(data) {
                if (typeof _prepareDataCallback === "function") {
                    return _prepareDataCallback(data);
                }
                return data;
            }

            /**
             * Вызывает колбек после рендеринга шаблона
             * @param $row
             */
            function riseRenderedTemplateCallback($row) {
                if (typeof _renderedTemplateCallback === "function") {
                    return _renderedTemplateCallback($row);
                }
            }

            /**
             * Подготовка данных перед передачей в шаблон
             * @param data
             * @returns {*}
             */
            function prepareData(data) {
                data.index = utils.randomString(4);
                data.totalElementsByCatId = $(utils.renderTemplate(config.addedRowsMask, {'category_id': data.category_id})).length;
                data.parentId = getResultGridGroupId();
                data.postArrayName = config.postArrayName;
                return risePrepareDataCallback(data);
            }

            /**
             * Добавить элемент в расчёт
             */
            function addToCalculation($target) {
                var $tr = $target.parents('tr'),
                    $countInput = $tr.find(config.countinputSelector),
                    data = $tr.data('element'),
                    $allElementsByValue = $('[value=' + data.id + ']'),
                    totalElementsByValue = 0,
                    $wrapper = $(buildWrapperId(getResultGridGroupId())),
                    $tbody = $(buildTbodyId(getResultGridGroupId())),
                    $row;

                data.count = $countInput.length > 0 ? parseInt($countInput.val()) : "";
                data = prepareData(data);

                hbs.render(config.templateName, function (template) {
                    $row = $(template(data));
                    $row.appendTo($tbody);
                    $wrapper.show();
                    initCounters("#" + $row.attr('id') + " " + config.counterInputSelector);
                    riseRenderedTemplateCallback($row);
                    totalElementsByValue = data.count;
                    $allElementsByValue.each(function () {
                        var input = $(this).closest('tr').find('input[type=text]');
                        if (input.length !== 0) {
                            totalElementsByValue += parseInt(input.val());
                        }
                    });
                    showAlert($target, data.count, totalElementsByValue);
                });
            }

            /**
             * Отправка поискового запроса
             * @returns {boolean}
             */
            function submitSearchForm() {
                var $this = $(this),
                    $form = $this.closest('form'),
                    $resultContainer = $(config.resultsContainerSelector);

                $.ajax({
                    url: $this.attr("action"),
                    type: 'get',
                    dataType: 'html',
                    data: $form.serialize(),
                    success: function (data) {
                        // Вывести полученные элементы
                        var content = $(data).filter(config.parsingResultContent);
                        $resultContainer.html(content.html());
                        $(document).trigger('reposition.bs.modal');
                        addAjaxScripts(data);
                        setResultGrid();
                        initCounters(config.resultsGridSelector + " " + config.counterInputSelector);
                        processHighLights();
                    }
                });
                return false;
            }

            /**
             * Устанавливает колбек подготовки данных
             * @param callback
             */
            this.setPrepareDataCallback = function (callback) {
                if (typeof callback === "function") {
                    _prepareDataCallback = callback;
                }
            };

            /**
             * Устанавливает коллбек вызываемый после пендеринга шаблона
             * @param callback
             */
            this.setRenderedTemplateCallback = function (callback) {
                if (typeof callback === "function") {
                    _renderedTemplateCallback = callback;
                }
            };

            this.initialize = function (options) {
                $.extend(config, options);
                $(document).on("modalShown.TbModalButton", function (e, modal) {
                    var $modal = $(modal),
                        articlesForm = $modal.find(config.articlesFormSelector);
                    if (articlesForm.length > 0) {
                        articlesForm.off('submit').on('submit', submitSearchForm);
                    }
                });
                $(document).off('click.ArticlesSearchForm', config.addToCalculationSelector)
                    .on('click.ArticlesSearchForm', config.addToCalculationSelector, function (e) {
                        e.preventDefault();
                        addToCalculation($(this));
                    });
                $(document).off('click.ArticlesSearchForm', config.removeAdditionalElementSelector)
                    .on('click.ArticlesSearchForm', config.removeAdditionalElementSelector, function (e) {
                        e.preventDefault();
                        removeAdditionalElement($(this));
                    });
            };
        };
    }
}(jQuery));