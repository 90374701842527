/**
 * Created by php-developer on 14.11.17.
 */
/*global jQuery, window, PaintingFormWidget, ArticlesSearchForm, utils*/
(function ($) {
    "use strict";

    var config = {
            colorsListUrl: "",
            colorMarkupUrl: "",
            formSelector: "#Line-equipment-form",
            counterSelector: ".counter"
        },

        $form,

        colors = [];

    /**
     * Получает список цветов
     */
    function loadColors() {
        $.ajax({
            url: config.colorsListUrl,
            dataType: 'json',
            type: 'get',
            success: function (response) {
                colors = response;
            }
        });
    }

    /**
     * Инициализирует каунтеры
     * @param selector
     */
    function initCounters(selector) {
        $(selector).counter({
            plusButtonClass: 'btn btn-default counter-plus',
            minusButtonClass: 'btn btn-default counter-minus',
            plus: '<span class="fa fa-chevron-right"></span>',
            minus: '<span class="fa fa-chevron-left"></span>',
            pattern: '[0-9]+',
            min: 0
        });
    }

    function initialize() {
        $form = $(config.formSelector);
        config.colorMarkupUrl = $form.data('color-markup-url');
        config.colorsListUrl = $form.data('colors-list-url');
        loadColors();
        var paintingFormWidget = new PaintingFormWidget();
        paintingFormWidget.initialize();
        initCounters(config.formSelector + " " + config.counterSelector);


        var articlesSearchForm = new ArticlesSearchForm();
        articlesSearchForm.setPrepareDataCallback(function (data) {
            data.colors = colors;
            data.colorMarkupUrl = utils.renderTemplate(config.colorMarkupUrl, {'series_id': data.series_id});
            return data;
        });
        articlesSearchForm.setRenderedTemplateCallback(function ($row) {
            $row.find('.select2').select2({allowClear: true, width: 'resolve'});
            paintingFormWidget.initialize();
        });
        articlesSearchForm.initialize({
            postArrayName: 'ModuleEquipmentForm',
            removeRows: true,
            templateName: 'calculationArticlesSearchForm.hbs'
        });
    }

    if (window.calculationEquipmentPage === undefined) {
        window.calculationEquipmentPage = {
            initialize: initialize
        };
    }

}(jQuery));