/*global mathUtils*/
(function (global) {

    'use strict';

    var fabric = global.fabric || (global.fabric = {});

    if (fabric.MOC45) {
        fabric.warn('fabric.MOC45 is already defined.');
        return;
    }

    /**
     * Triangle class
     * @class fabric.Triangle
     * @extends fabric.Object
     * @return {fabric.Triangle} thisArg
     * @see {@link fabric.Triangle#initialize} for constructor definition
     */
    fabric.MOC45 = fabric.util.createClass(fabric.Object, /** @lends fabric.MOC45.prototype */ {

        type: 'MOC45',

        /**
         * Constructor
         * @param {Object} [options] Options object
         * @return {Object} thisArg
         */
        initialize: function (options) {
            this.callSuper('initialize', options);
            this.set('width', options && options.width ? parseFloat(options.width) : 54)
                .set('height', options && options.height ? parseFloat(options.height) : 72.7)
                .set('append', options && options.append ? parseInt(options.append) : 8)
                .set('startAngle', options && options.startAngle ? options.startAngle * Math.PI : 1.4 * Math.PI)
                .set('endAngle', options && options.endAngle ? options.endAngle * Math.PI : 1.6 * Math.PI);
        },

        /**
         * измеряет длину отрезка
         * @param A
         * @param B
         * @returns {number}
         */
        _lineLength: function (A, B) {
            return Math.sqrt(Math.pow(A.x - B.x, 2) + Math.pow(A.y - B.y, 2));
        },

        /**
         * @private
         * @param {CanvasRenderingContext2D} ctx Context to render on
         */
        _render: function (ctx) {
            var widthBy2 = this.width / 2,
                heightBy2 = this.height / 2;

            ctx.beginPath();
            ctx.moveTo(widthBy2, -(heightBy2 - this.append));
            ctx.lineTo(0, heightBy2);
            ctx.lineTo(-widthBy2, -(heightBy2 - this.append));

            ctx.arc(
                0,
                heightBy2 - this.append,
                this._lineLength({x: 0, y: heightBy2 - this.append}, {x: widthBy2, y: -(heightBy2 - this.append)}),
                this.startAngle,
                this.endAngle,
                false);
            ctx.closePath();

            this._renderFill(ctx);
            this._renderStroke(ctx);
        }
    });

    /**
     * Returns {@link fabric.Triangle} instance from an object representation
     * @static
     * @memberOf fabric.Triangle
     * @param {Object} object Object to create an instance from
     * @param {function} [callback] invoked with new instance as first argument
     */
    fabric.MOC45.fromObject = function (object, callback) {
        return fabric.Object._fromObject('MOC45', object, callback);
    };

})(typeof exports !== 'undefined' ? exports : this);